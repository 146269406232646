export const POINTER = "Select"
export const BOX = "Box"
export const CIRCLE = "Circle"
export const POLYGON = "Polygon"
export const POLYLINE = "Polyline"
export const MARKER = "Marker"
export const TESTINGTOOL="testingtool"
export const TRAININGTOOL="trainingtool"
export const ACCURACYTOOL="accuracytool"
export const DETECTIONTOOL="detectiontool"
export const UPDATEREGION = "updatedRegions"
export const EDITDETECTION = "editDetection"
export const EDITDETECTIONS = "editDetections"
export const DELETEDETECTION = "deleteDetection"
export const CLEARDETECTIONS = "clearDetections"
export const CLEARAREAS="clearAreas"
export const UPDATECLASS = "updatedClasses"
export const FIRENOTALLOWEDERROR = "firedNotAllowedError"
export const OUTSIDEORTHOREGIONERROR = "regionOutsideBounds"
export const UPDATEREGIONSANDCLASSES = "UPDATEREGIONSANDCLASSES"
export const AREAOVERLAP="areaoverlap"
export const LINEINTERSECTITSELF="orthoLineIntersect"
export const POINTSONCIRCLE = 36
export const FILETYPES = {
    JSON: 'application/json',
    ZIP: "application/zip",
    WINDOWSZIP:"application/x-zip-compressed",
    VGG:"application/json",
    XML:"text/xml",
    CVS:"text/csv",
    TXT:"text/plain",
}
export const REGIONHIGHLIGHTSTYLE = {
    color: "white"
}
export const DEFAULTCLASSNAME = "class_01"
export const COPYINGREGIONSTYLE = {
    color: "white",
    fillColor: "yellow"
}
export const DETECTIONCOLOR="red"
export const TRAININGCOLOR="#08BB2A"
export const TESTINGCOLOR="#FFFD00"
export const ACCURACYCOLOR="#00A3FF"
import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SingleMap from '../../../ReusableComponents/map'
import { AnnotationTool } from "./MapAnnotationTool";
import * as API from "../../../api.js";
import { skipTrainingTaskTour, getLocalStorageValue, isTaskEditable, getLimitedName, Popup } from "../../../ReusableComponents/reusableFunctions";
import ReactJoyride, { BeaconRenderProps, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import AppConstants, { MAP_URLS, TASK } from "../../../AppConstants";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import ReusableImageSlider from "../../../ReusableComponents/ReusableImageSlider";
import SliderIcon from "../../../assets/Icons/gallery.png"
import closeButton from '../../../assets/Icons/cancel.png';
import MapTopSection from "./MapTopSection.js";


let extendBounds = 0.00075
// pass annotationTool as true if you want annotation feature, otherwise only orthomosaic will be loaded omn map
const MapAnnotation = (props) => {
    const [map, setMap] = useState(undefined)
    const [mapInfo, setMapInfo] = useState(undefined)
    const [bounds, setBounds] = useState(undefined)
    const [joyrideTrigger, setJoyrideTrigger] = useState(undefined)
    const [errorMessage, setError] = useState(undefined)
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [processPopupIcon, setPopupIcon] = useState(undefined)
    const [processMessage, setProcessMessage] = useState(undefined)
    const [annotations, setAnnotations] = useState(0)
    const [annotationToolLoaded, setAnnotationToolLoaded] = useState(false)
    const [rerenderComponent, setRerenderComponent] = useState(0)
    const [showImageSlider, setShowImageSlider] = useState(props.showImageSliderDefault || true)
    const [loading, setLoading] = useState(true)
    const annotationTool = useRef();
    useEffect(() => {
        if (!rerenderComponent) setRerenderComponent(1)
    }, [rerenderComponent])


    useEffect(() => {
        if (map) getMapInfo(map)
    }, [props.selectedImageForAnnotation.file_name])

    useEffect(() => {
        if (annotationTool.current && !annotationToolLoaded) {
            setAnnotationToolLoaded(true)
        }
    }, [annotationTool.current])

    const mapInitialized = (map) => {
        map.createPane('Areas')
        map.createPane('ortho');
        map.createPane('annotation')
        setMap(map)
        getMapInfo(map)

    }

    const getMapInfo = (map, requiredData) => {
        const file_name = requiredData?.file_name || props.selectedImageForAnnotation.file_name
        API.getTilesBounds({
            taskId: props.selectedImageForAnnotation.collection_id,
            tiffType: file_name,
            sas: encodeURIComponent(props.permissions?.st),
            blobContainer: props.permissions?.container
        }).then((mapInfo) => {
            setMapInfo(mapInfo)
            setMapCenter(mapInfo, map)
            loadOrtho(mapInfo, map)
            drawBoundsBorder(map, mapInfo.bounds)
        })
    }

    const setMapCenter = async (info, map1) => {
        if (info && map1 && info?.bounds) {
            let bounds = [[info.bounds[1] - extendBounds, info.bounds[0] - extendBounds], [info.bounds[3] + extendBounds, info.bounds[2] + extendBounds]]
            map1.fitBounds(bounds, { duration: 2.5 });
        } else {
            let bounds = [[mapInfo.bounds[1] - extendBounds, mapInfo.bounds[0] - extendBounds], [mapInfo.bounds[3] + extendBounds, mapInfo.bounds[2] + extendBounds]]
            map.fitBounds(bounds, { duration: 2.5 });
        }
    }

    const closeActivityPopup = () => {
        setShowProcessPopup(false)
    }


    const drawBoundsBorder = async (map, bounds) => {
        const drawingBounds = [[bounds[1], bounds[0]], [bounds[3], bounds[2]]]
        // const orthoBackground = L.rectangle(drawingBounds, { color: '#666666', weight: 1, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const orthoBackground = L.rectangle(drawingBounds, { color: '#2989CF', weight: 2, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const boundsLayer = L.rectangle(drawingBounds, { color: 'red', weight: 1, fillColor: "none", interactive: false, }) // for blink
        setBounds({
            drawingBounds, boundsLayer
        })
        setLoading(false)
        setJoyrideTrigger(getLocalStorageValue('mapAnnotationTour') == 1 && props.showAnnotationTool)
    }
    const loadOrtho = (mapInfo, map) => {
        map.getPane('Areas').style.zIndex = 1001
        map.getPane('annotation').style.zIndex = 1002
        const sas = encodeURIComponent(props.permissions?.st)
        let bounds = [[mapInfo.bounds[1], mapInfo.bounds[0]], [mapInfo.bounds[3], mapInfo.bounds[2]]]
        let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${props.selectedImageForAnnotation.collection_id}/${props.selectedImageForAnnotation.file_name}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${props.permissions?.container}`, {
            tileSize: 256,
            maxZoom: 28,
            pane: 'ortho',
            bounds: bounds
        }).addTo(map);
        if (props.onOrthoLoad) props.onOrthoLoad(map)
    }


    const [enableCalculateMesBtn, setEnableCalulateMesBtn] = useState(false)
    const [guideSteps, setGuideSteps] = useState([
        {
            disableBeacon: true,
            target: '#semi-step1',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Start adding class labels which will be used for annotations</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Add Class Label</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#draw-tool',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Start annotations using drawing tool</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Start Annotations</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#done-button',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to save annotations</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Save Annotations</div>),
            placement: 'bottom',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
    ])


    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem('mapAnnotationTour', 2)
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipTrainingTaskTour()
        }
    };

    const onClickPrev = () => {
        const sel = (props.selectedImageForAnnotation.key - 1 < 0) ? 0 : props.selectedImageForAnnotation.key - 1
        const image = props.orthoList.report.find((img, i) => i === sel)
        props.setSelectedImageForAnnotation({ image: image.image, key: sel, ...image }, () => {
            setMap(null)
            setRerenderComponent(prev => prev ? 0 : 1)
        })
    }

    const onClickNext = () => {
        const sel = (props.selectedImageForAnnotation.key + 1 >= props.orthoList.report.length) ? props.selectedImageForAnnotation.key : props.selectedImageForAnnotation.key + 1
        const image = props.orthoList.report.find((img, i) => i === sel)
        props.setSelectedImageForAnnotation({ image: image.image, key: sel, ...image }, () => {
            setMap(null)
            setRerenderComponent(prev => prev ? 0 : 1)
        })
    }

    const onClickImage = (image, selected) => {
        props.setSelectedImageForAnnotation({ image: image.image, key: selected, ...image }, () => {
            setMap(null)
            setRerenderComponent(prev => prev ? 0 : 1)
        })
    }

    const getNoWarningList = () => {
        let noWarningList = localStorage.getItem('no-warning-list')
        if (noWarningList) {
            noWarningList = JSON.parse(noWarningList)
        } else {
            noWarningList = []
        }
        return noWarningList
    }

    const canGoToNextImageFunc = () => {
        const noWarningList = getNoWarningList()
        const imageInNoWrningList = noWarningList.find(id => props.selectedImageForAnnotation.id == id)
        return props.task.status !== TASK.STATUS.CREATING || props.task.tasktype !== 1 || imageInNoWrningList || (annotationTool?.current && annotationTool.current.isCurrImageValid() == true)
    }
    useEffect(() => {
        if (annotationTool.current && !annotationToolLoaded) {
            setAnnotationToolLoaded(true)
        }
    }, [annotationTool.current])

    let annotationWarning = {
        type: 'WARNING',
        msg: <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>This image will not be considered for training if following conditions are not met, </div>
            <div style={{ color: "red" }}>Orthomosaic image should have atleast 20 annotations inside marked areas, with each area containing atleast 3 annotations to be considered valid for training, </div>
            <div>Are you sure you want to continue?</div>
        </div>,
        onClickClose: () => { },
        height: '285px'
    }
    return <>
        {

            (loading || !map || !props.permissions) && <div style={{ width: "100%", height: "100%", position: "absolute", backgroundColor: "#eaedf0", zIndex: 2, left: 0, top: 0 }}><LoaderComponent height={"90%"} message={"Loading map please wait..."} /></div>
        }
        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={showProcessPopup}
            icon={processPopupIcon}
            msg={processMessage}
            close={() => closeActivityPopup()}
            onClickOk={() => {
                annotationTool.current.clearAllAnnotations()
                closeActivityPopup()
            }}
        />
        {/* --------------------------------------------------------------- */}
        {
            joyrideTrigger ?
                <ReactJoyride
                    continuous={true}
                    run={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    showSkipButton={true}
                    steps={guideSteps}
                    disableOverlayClose={true}
                    floaterProps={AppConstants.FLOATER_PROPS}
                    locale={AppConstants.LOCALE_BUTTONS}
                    callback={(e) => handleJoyrideCallback(e)}
                    styles={{
                        buttonNext: AppConstants.NEXT_BUTTON,
                        buttonSkip: AppConstants.SKIP_BUTTON,
                        spotlight: AppConstants.SPOTLIGHT,
                        options: AppConstants.MAIN_OPTIONS
                    }}
                /> : <></>
        }


        {/* TOP BAR */}
        {props.showAnnotationTool && annotationTool?.current && annotationToolLoaded && <MapTopSection
            {...props}
            enableCalculateMesBtn={enableCalculateMesBtn}
            annotationTool={annotationTool}
            annotations={annotations}
            isTaskEditable={isTaskEditable}
            annotationToolLoaded={annotationToolLoaded}
        />}

        <div style={{ height: 'calc(100% - 44px)', width: '100%', marginTop: '8px', background: 'white', display: "flex", justifyContent: "space-between", paddingBottom: "24px" }}>
            <div style={{ height: '100%', width: props.showAnnotationTool ? '80%' : '100%', position: "relative" }}>
                {/* MAP */}
                {
                    <SingleMap
                        key={rerenderComponent}
                        initBaseLayerURL={MAP_URLS.ROAD_MAP}
                        initZoom={18}
                        maxZoom={25}
                        setMap={(map) => {
                            setLoading(true)
                            mapInitialized(map)
                        }}
                    >
                    </SingleMap>
                }

                {/* IMAGE SLIDER */}
                {
                    map && bounds && props.showSlider && <div style={{ position: "absolute", bottom: "0", width: "100%", left: "0" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "right" }}>
                            {
                                props.orthoList.report.length > 1 && <div id="myAnimation" className="slide" style={{ pointerEvents: "all", cursor: 'pointer', height: '32px', zIndex: 2 }} >
                                    <img src={showImageSlider ? closeButton : SliderIcon} style={{
                                        width: "30px",
                                        marginRight: "3px",
                                        borderRadius: "30px",
                                        backgroundColor: 'rgba(0,0,0,0.8)',
                                    }}
                                        onClick={() => {
                                            setShowImageSlider(prev => !prev)
                                        }} />
                                </div>
                            }
                        </div>
                        {
                            showImageSlider && props.orthoList.report.length > 1 &&
                            <ReusableImageSlider
                                styles={{
                                    slideStyle: {
                                        position: ""
                                    }
                                }
                                }
                                imageList={props.orthoList.report}
                                selectedImageIndex={
                                    props.orthoList.report.findIndex((r) => (props.selectedImageForAnnotation.file_name === r.image))}
                                onClickImage={(image, selected) => {
                                    if (canGoToNextImageFunc()) {
                                        onClickImage(image, selected)
                                    } else {
                                        const { type, msg, onClickClose, height } = annotationWarning
                                        Popup.alert(type,
                                            msg,
                                            () => {
                                                let noWarningList = getNoWarningList()
                                                if (noWarningList.find(id => id == props.selectedImageForAnnotation.id)) {
                                                    //if the image is already present in no warning list
                                                } else {

                                                    noWarningList.push(props.selectedImageForAnnotation.id)
                                                    localStorage.setItem('no-warning-list', JSON.stringify(noWarningList))
                                                }
                                                onClickImage(image, selected)
                                            },
                                            onClickClose,
                                            height
                                        )
                                    }
                                }}
                                onClickNext={() => {

                                    if (canGoToNextImageFunc()) {
                                        onClickNext()
                                    }
                                    else {
                                        const { type, msg, onClickClose, height } = annotationWarning
                                        Popup.alert(type,
                                            msg,
                                            () => {
                                                let noWarningList = getNoWarningList()
                                                if (noWarningList.find(id => id == props.selectedImageForAnnotation.id)) {
                                                    //if the image is already present in no warning list
                                                } else {
                                                    noWarningList.push(props.selectedImageForAnnotation.id)
                                                    localStorage.setItem('no-warning-list', JSON.stringify(noWarningList))
                                                }
                                                onClickNext()
                                            },
                                            onClickClose,
                                            height
                                        )
                                    }
                                }}
                                onClickPrev={() => {
                                    if (canGoToNextImageFunc()) {
                                        onClickPrev()
                                    } else {
                                        //show warning
                                        const { type, msg, onClickClose, height } = annotationWarning
                                        Popup.alert(type,
                                            msg,
                                            () => {
                                                let noWarningList = getNoWarningList()
                                                if (noWarningList.find(id => id == props.selectedImageForAnnotation.id)) {
                                                    //if the image is already present in no warning list
                                                } else {

                                                    noWarningList.push(props.selectedImageForAnnotation.id)
                                                    localStorage.setItem('no-warning-list', JSON.stringify(noWarningList))
                                                }
                                                onClickPrev()
                                            },
                                            onClickClose,
                                            height
                                        )
                                    }
                                }}
                                permissions={props.permissions}
                                requiredData={[props.task.image_collection_id]}
                            />
                        }
                    </div>
                }
            </div>

            {/* ANNOTATION TOOL*/}
            {
                !loading && map && bounds && props.selectedImageForAnnotation && props.showAnnotationTool && <div style={{ height: '100%', width: '20%', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <AnnotationTool
                        enableCalculateMesBtnFunc={(val) => setEnableCalulateMesBtn(val)}
                        permissions={props.permissions}
                        showLoader={(event, msg, icon) => {
                            if (event) {
                                setPopupIcon(icon)
                                setProcessMessage(msg)
                                setShowProcessPopup(true)
                            }
                            else {
                                setShowProcessPopup(false)
                            }
                        }}
                        ref={annotationTool}
                        setAnnotationsCount={setAnnotations}
                        bounds={bounds}
                        task={props.task}
                        annotationSetId={props.annotationSetId}
                        imageInfo={props.selectedImageForAnnotation}
                        isMultipleOrtho={props.orthoList.report.length > 1}
                        map={map}
                        updateTask={props.updateTask}
                        setMapCenter={setMapCenter}
                    />
                </div>
            }

        </div>
    </>
}

export default MapAnnotation;
import React, { Component } from "react";
import * as API from "../../api.js";
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import { getDistance, getArea, get3dPolygonArea, getDistanceIn3d } from "../../ReusableComponents/map/leafletMeasure.js";
import Loader from '../../icons/loader.svg';
import { checkURLExist, addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js'
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams/index.js";
import dateFormat from 'dateformat';
import NoPlanImg from "../../assets/Images/Noplan1.png";
import { BLOB_URL } from "../../AppConstants.js";
const NotSelected = "Not Selected";
class compareMeasurementReport extends Component {
  state = {
    isOwner: false,
    sharedBy: null,
    user: {},
    type: "",
    orthoUrl: '',
    demUrl: '',
    dtmUrl: '',
    pathDetailUrl: this.props.location.state.orthoImageData.image_url.replace("orthomosaic.", "topview."),
    reportData: [],
    showProcessPopup: false,
    loading: true,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    deleteReport_id: '',
    reportheader: {},
    reportTitle: '',
    reportSubTitle: '',
    company_logo: '',
    company_name: '',
    editHeader: false,
    company_logo1: undefined,
    titleEdit: false,
    subTitleEdit: false,
    showDTM: true,
    linesData: [],
    polygonData: [],
    circleData: [],
    markerData: [],
    outId: "",
    volumePolygonData: [],

    allMaps: [],
    allMeasurements: [],
    comparedMeasurements: [],
    polygonDataComp: [],
    polyLineDataComp: [],
    markerDataComp: [],
    circleDataComp: [],
    circleDataComp: [],
    loading2: false,
    calculationMethod: "highest",
    volumeLoading: false,
    isMeasurementAvailable: true,
  };

  handleChange = (e) => {
    let t = e.target;
    if (t.value === ' ') {
      console.log('space not allow');
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [t.name]: t.value,
      }))
    }

  }

  checkedTrue(index) {
    let temp = this.state.reportData
    temp[index].visibleInReport = !temp[index].visibleInReport
    this.setState((state) => ({
      ...state,
      reportData: temp
    }))
  }

  getElevation = (data, unit, taskId) => {
    return new Promise((resolve, reject) => {
      let { user, orthoImageData } = this.state
      let task_id = taskId ? taskId : orthoImageData.task_id;
      let dataObj = { task_id, blob_container: this.props.location.state.permissions.container, sas_token: this.props.location.state.permissions.st, lat: data.lat, lng: data.lng }
      API.getElevationOfPoint(dataObj).then(result => {
        let elevationData = result.elevation && result.elevation != 'NA' ? unit ? `${result.elevation.toFixed(2)} m` : result.elevation.toFixed(2) : 'NA'
        resolve(elevationData)
      })
    })
  }

  getPolylineStatistics = async (coordinates) => {
    return new Promise((resolve, reject) => {
      let { user, orthoImageData } = this.state
      API.getLineStatistics(orthoImageData.task_id, this.state.container, this.state.st, coordinates).then((lineStats) => {
        resolve(lineStats)
      }).catch((err) => {
        console.log(err);
        resolve({})
      })
    })

  }

  getAllDNReport = () => {
    let plan_id = this.state.orthoImageData.plan_id
    let data = []
    API.getGlobalMeasurements(plan_id).then(async (reportData) => {
      data = reportData.measurements
      console.log("measurements data : ", reportData.measurements)
      let polygonData = []
      let volumePolygonData = []
      let lineData = []
      let circleData = []
      let markerData = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let report = data[i]
          if (report.type == "Polygon") {
            const threedArea = await get3dPolygonArea(report.coordinates, 'm', false)
            const threedPerimeter = await getDistanceIn3d(report.coordinates)
            polygonData.push({ ...report, threedArea, threedPerimeter })
            if (report.volume) {
              volumePolygonData.push(report)
            }
          } else if (report.type == "Polyline") {
            if (this.state.DSMAvailable)
              report.stat = await this.getPolylineStatistics(report.coordinates)
            report.threedLength = await getDistanceIn3d(report.coordinates, "m", false)
            lineData.push(report)
          } else if (report.type == "Circle") {
            circleData.push(report)
          } else if (report.type == "Marker") {
            if (this.state.DSMAvailable)
              markerData.push(report)
          }
        }
        this.setState((state) => ({
          ...state,
          linesData: lineData,
          polygonData: polygonData,
          circleData: circleData,
          markerData: markerData,
          volumePolygonData: volumePolygonData,
          reportData: data,
        }))
      }
      this.setState((state) => ({
        ...state,
        reportData: data,
        loading: false,
      }))
    }).catch((e) => {
      this.setState((state) => ({
        ...state,
        reportData: data,
        loading: false,
      }))
    })
  }

  changeCompanyLogo = (event) => {
    // let companyLogo = event.target.value
    let targetFilesArr = [];
    targetFilesArr = Array.from(event.target.files);
    let companyLogo = targetFilesArr[0]
    this.setState((state) => ({
      ...state,
      company_logo1: companyLogo,
    }), () => {
      this.uploadCompanLogo()
    })
  }

  uploadCompanLogo = () => {
    const data = new FormData()
    data.append("file", this.state.company_logo1)
    API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
      console.log(uploadlogo.status)
      if (uploadlogo.status == 200) {
        this.setState((state) => ({
          ...state,
          companyLogo1: undefined,
        }), () => {
          this.getReportHeader()
        })
      } else {
        console.log('error while uploading logo')
      }
    })
  }

  updateReportHeader = () => {
    API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
      if (data.status == 200) {
        this.setState((state) => ({
          ...state,
          editHeader: false
        }))
        this.getReportHeader();
      } else {
        this.getReportHeader();
      }
    })
  }

  getReportHeader = () => {
    let task_id = this.state.orthoImageData.task_id
    let report_type = 'measurement'
    API.getReportHeader(task_id, report_type).then((data) => {
      if (data.data == null) {
        this.addReportHeader();
      } else {
        this.setState((state) => ({
          ...state,
          reportheader: data.data,
          reportTitle: data.data.title,
          reportSubTitle: data.data.subtitle,
          company_logo: data.data.company_logo ? `${data.data.company_logo}?${this.state.st}&t${new Date().getTime()}` : this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
          company_name: data.data.name,
        }))
      }
    })
  }

  addReportHeader = () => {
    let task_id = this.state.orthoImageData.task_id
    let report_type = 'measurement'
    let title = this.state.orthoImageData.task_name
    let subtitle = this.state.plan.plan_name
    let company_logo = null
    // this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`
    API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
      if (data.status == 201) {
        this.getReportHeader();
      } else {
        this.setState((state) => ({
          ...state,
          reportTitle: this.state.orthoImageData.task_name,
          reportSubTitle: this.state.plan.plan_name,
          company_logo: this.state.avatar_url ? `${this.state.avatar_url}?${this.state.st}&t${new Date().getTime()}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
          company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        }))
      }
    })
  }

  isUserOwnerOfCollection = () => {
    const { collection_id } = this.state.orthoImageData
    const { id } = this.state.project
    API.isUserProjectOwner(id, collection_id)
      .then((result) => {
        this.setState({
          isOwner: result.isShared ? false : true,
          isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
          sharedBy: result.sharedBy
        })
      })
      .catch((err) => {
        window.alert("Something Went Wrong")
      })
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
  }

  getMaps = () => {
    API.getAllMaps(" ", 0, 10000, 'plan', this.state.plan.id).then((mapData) => {
      this.setState({
        allMaps: mapData.data,
        loading2: true,
      }, () => {
        this.getMeasurements();
      })
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  getDSMAvailable = async (task_id, collection_id) => {
    try {
      return await checkURLExist(`${BLOB_URL}/${this.props.location.state.permissions.container}/orthomosaicImages/${task_id}/dem.tif?${this.props.location.state.permissions.st}`)
    }
    catch (e) {
      return false
    }
  }

  getVolume = (coordinate, task_id, collection_id, calculationMethod) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await API.getVolume(calculationMethod, this.props.location.state.permissions.container, this.props.location.state.permissions.st, task_id,
          coordinate)
        resolve(response);
      } catch (e) {
        resolve(false)
      }
    })
  }

  getLineStatistics = async (task_id, coordinates) => {
    try {
      const response = await API.getLineStatistics(task_id, this.props.location.state.permissions.container, this.props.location.state.permissions.st, coordinates)
      return await response;
    } catch (e) {
      return undefined;
    }

  }

  getTiffAvailable = async (task_id, collection_id) => {
    let tasks = await API.getTaskDetails(collection_id, 3);

    let DSMAvailable = await checkURLExist(`${BLOB_URL}/${this.props.location.state.permissions.container}/orthomosaicImages/${task_id}/dem.tif?${this.props.location.state.permissions.st}`) ?
      true : tasks.tasks[0].task_parameters && tasks.tasks[0].task_parameters.elevation ? false : NotSelected;
    let DTMAvailable = await checkURLExist(`${BLOB_URL}/${this.props.location.state.permissions.container}/orthomosaicImages/${task_id}/dtm.tif?${this.props.location.state.permissions.st}`) ?
      true : tasks.tasks[0].task_parameters && tasks.tasks[0].task_parameters.elevation ? false : NotSelected;
    let OrthomosaicAvailable = await checkURLExist(`${BLOB_URL}/${this.props.location.state.permissions.container}/orthomosaicImages/${task_id}/orthomosaic.tif?${this.props.location.state.permissions.st}`) ?
      true : tasks.tasks[0].task_parameters && tasks.tasks[0].task_parameters.elevation ? false : NotSelected;

    return (DSMAvailable && DSMAvailable != NotSelected ||
      DTMAvailable && DTMAvailable != NotSelected ||
      OrthomosaicAvailable && OrthomosaicAvailable != NotSelected)
  }

  getThreeDMeasurements = (taskId, measurements) => {
    return new Promise((resolve, reject) => {
      API.getMeasurementsInThreed(taskId, this.props.location.state.permissions, measurements).then((data) => {
        resolve(data.measurements?.[0]);
      }
      ).catch(e => {
        resolve(false)
      })
    })
  }

  getMeasurements = () => {
    this.setState({
      volumeLoading: true,
    });
    API.getGlobalMeasurements(this.state.orthoImageData.plan_id)
      .then(async (result) => {
        console.log("getGlobalMeasurements result", result)
        if (result.measurements) {
          this.setState({
            comparedMeasurements: await Promise.all(result.measurements.map(async measurement => {
              const coordinates = measurement.coordinates;
              return {
                id: measurement.id,
                name: measurement.name,
                type: measurement.type,
                description: measurement.description,
                coordinates: coordinates,
                maps: await Promise.all(this.state.allMaps.map(async mp => {

                  if (measurement.type === "Polygon") {
                    const measurement1 = [{
                      name: measurement.name,
                      type: measurement.name,
                      coordinates: coordinates.map(coord => { return { 'lat': coord.lat, 'lng': coord.lng } }),
                      description: null,
                      taskId: mp.task_id,
                      color: "red",
                    }]
                    const DSMAvailable = await this.getDSMAvailable(mp.task_id, mp.collection_id)
                    const ThreeD = DSMAvailable ? await this.getThreeDMeasurements(mp.task_id, measurement1) : false
                    const volumeData =
                      DSMAvailable ?
                        await this.getVolume(coordinates.map(coord => [coord.lng, coord.lat]), mp.task_id, mp.id, measurement.calc_method || this.props.location.state.calculationMethod || "highest") :
                        {}
                    return {
                      "mapName": mp.collection_name,
                      "mapDate": mp.processing_date,
                      "threeDPerimeter": ThreeD ? await getDistanceIn3d(ThreeD.coordinates, "m", false) : "-",
                      "twoDPerimeter": getDistance(coordinates, "m", true, !(this.getTiffAvailable(mp.task_id, mp.id))),
                      "twoDArea": getArea(coordinates, "m", true, !await this.getTiffAvailable(mp.task_id, mp.id)),
                      "threeDArea": ThreeD ? await get3dPolygonArea(ThreeD.coordinates, 'm', false) : "-",
                      "taskId": mp.task_id,
                      "cutVolume": volumeData?.cutVolume,
                      "fillVolume": volumeData?.fillVolume,
                      "volume": volumeData?.volume,
                      "calcMethod": volumeData?.calcMethod || this.props.location.state.calculationMethod || "highest",
                    }
                  }
                  else if (measurement.type === "Polyline") {
                    const measurement1 = [{
                      name: measurement.name,
                      type: measurement.name,
                      coordinates: coordinates.map(coord => { return { 'lat': coord.lat, 'lng': coord.lng } }),
                      description: null,
                      taskId: mp.task_id,
                      color: "red",
                    }]
                    const DSMAvailable = await this.getDSMAvailable(mp.task_id, mp.collection_id)
                    const ThreeD = DSMAvailable ? await this.getThreeDMeasurements(mp.task_id, measurement1) : false
                    const lineStats = await this.getLineStatistics(mp.task_id, coordinates);
                    return {
                      "mapName": mp.collection_name,
                      "mapDate": mp.processing_date,
                      "twoDLength": getDistance(coordinates, "m", true, !(this.getTiffAvailable(mp.task_id, mp.id))),
                      "threeDLength": ThreeD ? await getDistanceIn3d(ThreeD.coordinates, 'm', false) : "-",
                      "minElevation": lineStats?.minElevation,
                      "maxElevation": lineStats?.maxElevation,
                      "elevationDifference": lineStats?.elevationDifference,
                      "startElevation": lineStats?.startingElevation,
                      "endElevation": lineStats?.endingElevation,
                      "avgElevation": lineStats?.avgElevation,
                      "slope": lineStats?.slope
                    }
                  }
                  else if (measurement.type === "Marker") {
                    const markerData = await this.getElevation(coordinates, false, mp.task_id);
                    return {
                      "mapName": mp.collection_name,
                      "mapDate": mp.processing_date,
                      "elevation": Number(markerData).toFixed(2),
                      "location": [coordinates.lat, coordinates.lng]
                    }
                  }
                }
                ))
              }
            })
            )
          }, () => {
            this.sortMeasurements();
            this.setState({
              loading2: false,
              volumeLoading: false
            })
          })
        }
        else {
          this.setState({
            loading2: false,
            isMeasurementAvailable: false
          })
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  sortMeasurements = async () => {
    if (this.state.comparedMeasurements) {
      let polylineTempData = [];
      let polygonTempData = [];
      let markerTempData = [];
      this.state.comparedMeasurements.map(e => {
        if (e.type === "Polygon") {
          polygonTempData.push(e)
        }
        if (e.type === "Polyline") {
          polylineTempData.push(e)
        }
        if (e.type === "Marker") {
          markerTempData.push(e)
        }
      })
      this.setState({
        polyLineDataComp: polylineTempData,
        polygonDataComp: polygonTempData,
        markerDataComp: markerTempData
      })
    }
  }

  componentWillMount() {
    document.title = "DroneNaksha Report";
    getFeaturesPermissions([DRONENAKSHA_FEATURES.REPORTS]).then(permissions => {
      this.setState({
        permissions,
        st: permissions?.st,
        container: permissions?.container,
        avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`
      }, () => {
        addUserAnalytic(this.props.location.pathname)
          .then(id => {
            this.setState((state) => ({
              outId: id
            }))
          })

        if (this.props.location.state) {
          let { user, type, orthoImageData, task, plan, project, DSMAvailable } = this.props.location.state;
          this.setState((state) => ({
            ...state,
            user,
            type,
            task,
            orthoImageData,
            project,
            plan,
            DSMAvailable
          }), () => {
            this.isUserOwnerOfCollection();
            this.getReportHeader();
            this.getAllDNReport();
            this.getMaps();
          })
        } else {
          API.getUserType().then(
            ([blah, type, user]) => {
              this.setState(
                (state) => ({
                  ...state,
                  user,
                  type,
                })
              );
            },
            (e) => {
              window.location.replace("/login");
            }
          );
        }
      })
    })
      .catch(e => window.location.replace("/"))
  }

  render() {
    let { user, type, task, orthoUrl, demUrl, dtmUrl } = this.state;
    const handleClick = (event) => {
      document.getElementById("hiddenFileInput").click();
    };
    return (
      <>
        <div className="top-bar" style={{ position: 'fixed', width: '100%' }}>
          <div className="breadCrumbs">
            <BreadCrumbs data={[
              {
                displayName: "projects",
                pathname: "/dronenaksha/projects",
                state: {
                  type,
                  user,
                },
                style: {
                  color: "black",
                  fontSize: "12px"
                }
              },
              {
                displayName: this.props.location.state.project.project_name,

                pathname: "/dronenaksha/project",
                state: {
                  type,
                  user,
                  project: this.props.location.state.project,
                },
                style: {
                  color: "black",
                  fontSize: "12px"
                }

              },
              {
                displayName: this.props.location.state.plan.plan_name,

                pathname: "/dronenaksha/plan",
                state: {
                  type,
                  user,
                  plan: this.props.location.state.plan,
                  project: this.props.location.state.project
                },
                style: {
                  color: "black",
                  fontSize: "12px"
                }
              },
              {
                displayName: this.props.location.state.orthoImageData.task_name
              }
            ]} />
            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
          </div>
        </div>
        {
          this.state.isMeasurementAvailable ?
            this.state.editHeader || !this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].DOWNLOAD ? <button style={{ position: 'fixed', opacity: '0.5', top: '80px', right: '15px', cursor: "default" }} className="add-user-form-submit-button">
              Download
            </button> :
              <>
                <button style={{ position: 'fixed', top: '80px', right: '15px' }} className="add-user-form-submit-button" onClick={window.print}>
                  Download
                </button>
              </>
            :
            <></>
        }

        {
          this.state.isMeasurementAvailable ?
            this.state.loading ?
              <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
              </div> :
              <div style={{ display: 'flex', margin: '60px 15px', justifyContent: 'center' }}>
                <div style={{ width: '100%' }}>
                  <div id="pageprint1"
                    style={{
                      padding: "20px 30px 20px 30px",
                      width: "950px",
                      margin: 'auto',
                      boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                    }}
                  >
                    <div>
                      <input
                        name="company_logo"
                        id="hiddenFileInput"
                        type="file"
                        className=""
                        required
                        onChange={this.changeCompanyLogo}
                        accept='image/*'
                        // multiple
                        style={{ display: "none" }}
                      />
                      <div style={{ width: '885px', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                        <div style={{}}>

                          {/* this is tile edit */}
                          {this.state.subTitleEdit ?
                            <div style={{ height: '47px' }}>
                              {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                              <input maxLength={'20'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                className="edit-report-header-text" style={{}} onChange={this.handleChange} />
                              <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                this.setState(
                                  (state) => ({
                                    ...state,
                                    subTitleEdit: false,
                                    editHeader: false,
                                  }), () => {
                                    this.updateReportHeader()
                                  })
                              }} />
                              <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                this.setState(
                                  (state) => ({
                                    ...state,
                                    subTitleEdit: false,
                                    editHeader: false,
                                  }), () => {
                                    this.getReportHeader()
                                  })
                              }} />
                            </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                              <div style={{ fontSize: '32px', fontWight: '500' }}>{this.state.reportSubTitle}</div>
                              <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={() => {
                                this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                  (state) => ({
                                    ...state,
                                    subTitleEdit: true,
                                    editHeader: true,
                                  }))
                              }} />
                            </div>}

                        </div>

                        <div style={{}}>
                          <div style={{ textAlign: 'end', }}>
                            <img id="reportToolImage" className="reportToolImage" style={{ position: 'absolute', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && handleClick} />
                            <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={this.state.company_logo} />
                          </div>

                          {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                            {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                            <input maxLength={'20'} style={{ width: '200px' }} type="text" value={this.state.company_name} name='company_name'
                              className="edit-report-header-text" onChange={this.handleChange} />
                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                              this.setState(
                                (state) => ({
                                  ...state,
                                  companyNameEdit: false,
                                  editHeader: false
                                }), () => {
                                  this.updateReportHeader()
                                })
                            }} />
                            <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                              this.setState(
                                (state) => ({
                                  ...state,
                                  companyNameEdit: false,
                                  editHeader: false,
                                }), () => {
                                  this.getReportHeader()
                                })
                            }} />
                          </div> :
                            <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                              <div style={{ fontSize: '20px' }}>{this.state.company_name}</div>
                              <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? 'pointer' : "default", opacity: this.props?.location.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT ? "1" : "0.2", display: this.state.isOwner ? undefined : "none" }} src={edit} onClick={() => {
                                this.state.permissions?.[DRONENAKSHA_FEATURES.REPORTS].EDIT && this.setState(
                                  (state) => ({
                                    ...state,
                                    companyNameEdit: true,
                                    editHeader: true,
                                  }))
                              }} />
                            </div>
                          }
                        </div>
                      </div>
                      <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                    </div>
                    <div>
                      {this.state.loading2 ?
                        <div style={{ height: "100vh", width: "100%", display: "flex", textAlign: "center" }}>
                          <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div>
                        :
                        <>
                          {(this.state.polygonDataComp && this.state.polygonDataComp.length > 0) ?
                            <>
                              {this.state.polygonDataComp.map((item, index) => {
                                return <div>
                                  <div className="content-text" style={{ fontSize: "16px", margin: "20px 5px -10px 5px" }}>{`${item.name}`}
                                    <div style={{ marginTop: "5px" }}>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: "500",
                                          fontSize: "11px",
                                          lineHeight: "19px",
                                          position: "sticky",
                                        }}
                                      >
                                        <div>2D Area : {item.maps[0].twoDArea.split(" ")[0]} m<sup>2</sup></div>
                                        <div>2D Perimeter : {item.maps[0].twoDPerimeter.split(" ")[0]} m</div>
                                      </span>
                                    </div>
                                  </div>
                                  <table style={{ textAlign: "center" }} className="customers-table">
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "15%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "20%" : "25%" }} />
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    {/* <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                  <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} /> */}

                                    {this.props.location.state.DSMAvailable ? <>
                                      <col style={{ width: "13%" }} />
                                      <col style={{ width: "13%" }} />
                                    </> : <></>}
                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                      <th style={{ textAlign: "left" }} >Maps</th>
                                      <th style={{ textAlign: "left" }} >Date</th>
                                      {/* <th>2D Area (m<sup>2</sup>)</th> */}
                                      <th>3D Area (m<sup>2</sup>)</th>
                                      {/* <th>2D Perimeter(m)</th> */}
                                      <th>3D Perimeter(m)</th>
                                      <th>Cut Volume ({this.props.location.state.calculationMethod})</th>
                                      <th>Fill Volume ({this.props.location.state.calculationMethod})</th>
                                      <th>Volume ({this.props.location.state.calculationMethod})</th>
                                    </tr>
                                    {
                                      item.maps.map((mapData, i) => {
                                        return <>
                                          <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td style={{ textAlign: "left", fontSize: '11px' }}>{mapData.mapName}</td>
                                            <td style={{ textAlign: "left", fontSize: '11px' }}>{dateFormat(mapData.mapDate, "dd mmm yyyy")}</td>
                                            {/* <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.twoDArea ? Number(mapData.twoDArea.split(" ")[0]).toFixed(3) : "-"}</td> */}
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{isNaN(mapData.threeDArea) ? "-" : Number(mapData.threeDArea).toFixed(3)}</td>
                                            {/* <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.twoDPerimeter ? Number(mapData.twoDPerimeter.split(" ")[0]).toFixed(3) : "-"}</td> */}
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{isNaN(mapData.threeDPerimeter) ? "-" : Number(mapData.threeDPerimeter).toFixed(3)}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{this.state.volumeLoading ? "Calculating..." : mapData.cutVolume ? Number(mapData.cutVolume).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{this.state.volumeLoading ? "Calculating..." : mapData.fillVolume ? Number(mapData.fillVolume).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{this.state.volumeLoading ? "Calculating..." : mapData.volume ? Number(mapData.volume).toFixed(3) : "-"}</td>
                                          </tr>
                                        </>
                                      })
                                    }
                                  </table>
                                </div>
                              })
                              }
                            </> : ""}

                          {(this.state.polyLineDataComp && this.state.polyLineDataComp.length > 0) ?
                            <>
                              {this.state.polyLineDataComp.map((item, index) => {
                                return <div>
                                  <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px -10px 5px" }}>{`${item.name}`}
                                    <div style={{ marginTop: "5px" }}>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: "500",
                                          fontSize: "11px",
                                          lineHeight: "19px",
                                          position: "sticky",
                                        }}
                                      >
                                        <div>2D Length : {item.maps[0].twoDLength.split(" ")[0]} m</div>
                                      </span>
                                    </div>
                                    {/* <span
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      lineHeight: "19px",
                                      letterSpacing: " 0.01em",
                                      position: "sticky",
                                      top: "0",
                                    }}
                                  >(
                                    2D Length : {item.maps[0].twoDLength.split(" ")[0]} m
                                    )</span> */}
                                  </div>
                                  <table style={{ textAlign: "center" }} className="customers-table">
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "80%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "12%" : "25%" }} />

                                    {this.props.location.state.DSMAvailable ? <>
                                      <col style={{ width: "13%" }} />
                                      <col style={{ width: "13%" }} />
                                    </> : <></>}

                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                      <th style={{ textAlign: "left" }}>Maps</th>
                                      <th style={{ textAlign: "left" }} >Date</th>
                                      <th>3D Length(m)</th>
                                      <th>Min ele(m)</th>
                                      <th>Max ele(m)</th>
                                      <th>Start ele(m)</th>
                                      <th>End ele(m)</th>
                                      <th>Slope</th>
                                      <th>Elevation (avg)</th>
                                      <th>Eleveation Difference</th>
                                    </tr>

                                    {
                                      item.maps.map((mapData, i) => {
                                        return <>
                                          <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td style={{ textAlign: "left", fontSize: '11px' }}>{mapData.mapName}</td>
                                            <td style={{ textAlign: "left", fontSize: '11px', paddingRight: "0", paddingLeft: "20px" }}>{dateFormat(mapData.mapDate, "dd mmm yyyy")}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{isNaN(mapData.threeDLength) ? "-" : Number(mapData.threeDLength).toFixed(3)}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.minElevation ? Number(mapData.minElevation).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.maxElevation ? Number(mapData.maxElevation).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.startElevation ? Number(mapData.startElevation).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.endElevation ? Number(mapData.endElevation).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.slope ? Number(mapData.slope).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.avgElevation ? Number(mapData.avgElevation).toFixed(3) : "-"}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{mapData.elevationDifference ? Number(mapData.elevationDifference).toFixed(3) : "-"}</td>
                                          </tr>
                                        </>
                                      })
                                    }
                                  </table>
                                </div>
                              })
                              }
                            </> : ""}

                          {this.state.markerDataComp && this.state.markerDataComp.length > 0 ?
                            <>
                              {this.state.markerDataComp.map((item, index) => {
                                return <div>
                                  <div className="content-text" style={{ fontSize: "16px", margin: "50px 5px -10px 5px" }}>{`${item.name}`}
                                    <div style={{ marginTop: "5px" }}>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: "500",
                                          fontSize: "11px",
                                          lineHeight: "19px",
                                          position: "sticky",
                                        }}
                                      >
                                        <div>Location : {`[${item.maps[0].location}]`}</div>
                                        {/* <div>Location : {` testing `}</div> */}
                                      </span>
                                    </div>
                                    {/* <span
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      lineHeight: "19px",
                                      letterSpacing: " 0.01em",
                                      position: "sticky",
                                      top: "0",
                                    }}
                                  >( Location : {`{${item.maps[0].location}}`} )</span> */}
                                  </div>
                                  <table style={{ textAlign: "center" }} className="customers-table">
                                    <col style={{ width: "20%" }} />
                                    <col style={{ width: this.props.location.state.DSMAvailable ? "15%" : "20%" }} />
                                    <col style={{ width: "60%" }} />
                                    <tr className="customers-table-header-row" style={{ position: 'inherit' }}>
                                      <th style={{ textAlign: "left" }}>Maps</th>
                                      <th style={{ textAlign: "left" }} >Date</th>
                                      <th>Elevation (m)</th>
                                    </tr>
                                    {
                                      item.maps.map((mapData, i) => {
                                        return <>
                                          <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td style={{ textAlign: "left", fontSize: '11px' }}>{mapData.mapName}</td>
                                            <td style={{ textAlign: "left", fontSize: '11px' }}>{dateFormat(mapData.mapDate, "dd mmm yyyy")}</td>
                                            <td style={{ fontSize: '11px', padding: '0px 10px' }}>{`${mapData.elevation ? mapData.elevation == 'NaN' ? "-" : mapData.elevation : "-"}`}</td>
                                          </tr>
                                        </>
                                      })
                                    }
                                  </table>
                                </div>
                              })
                              }
                            </>
                            : ""}
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div >
            :
            <div style={{ height: "100vh", width: "100%", textAlign: "center", marginTop: "10%" }}>
              <div className="rmp-dashboard-empty-data-message">
                <img style={{ height: '25%', width: '25%' }} src={NoPlanImg} />
                <p style={{ margin: '-35px' }}>{`No measurements added in compare report`}</p>
              </div>
            </div>
        }
      </>
    );
  }
}

export default compareMeasurementReport;

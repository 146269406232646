import React, { Component, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Tiff from 'tiff.js'
import { connect } from "react-redux";
import * as API from "../../api.js";
import Loader from "../../icons/loader.svg";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import { v4 as uuid } from 'uuid';
import edit from '../../icons/pen.png'
import saveImage from '../../icons/saveImage.png'
import close from '../../assets/Icons/Close.png'
import Annotation from "./Annotation"
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
} from 'chart.js';
import { Pie } from "react-chartjs-2";
import CropAnnotation from "./ReusableComp/CropAnnotation";
import dateFormat from "dateformat";
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup.js";
import prevIcon from "../../assets/Icons/prev_icon.png"
import nextIcon from "../../assets/Icons/next_icon.png"
import { Popup, checkURLExist, getLimitedName, getRegionNameAfterUnderscore, getRegionNameBeforeUnderscore, getSevirityNumber } from "../../ReusableComponents/reusableFunctions.js";
import AppConstants from "../../AppConstants.js";
import { ActiveRole, getFeaturesPermissions, PICSTORK_FEATURES } from "../../Teams/index.js";
import PS from '../../windMill/HP.png'
import LE from '../../windMill/LE.png'
import TE from '../../windMill/TE.png'
import SS from '../../windMill/LP.png'
import { BLOB_URL } from "../../AppConstants";
import CropOrthoImage from "./CropOrthoImage.js";
import MapDetections from "./ReusableComp/MapDetections.js";
import InputWithSaveAndEdit from "./ReusableComp/InputWithSaveAndEdit.js";
import DropDownWithButton from "../../ReusableComponents/DropDownWithButton.js";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon.js";
import WhiteCloseIcon from "../../assets/toolbarIcons/WhiteCloseIcon.png";
import * as turf from '@turf/turf'
import Button from "../../ReusableComponents/Button/Button.js";
const graphColours = [
  'rgba(198, 252, 20,1)',
  'rgba(255, 227, 15, 1)',
  'rgba(252, 161, 3, 1)',
  'rgba(247, 30, 10, 1)',
  'rgba(148, 3, 3, 1)',
  'rgba(15, 158, 219, 1)',
]
const cookie = new Cookies()
class PicStorkDefectReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enable_generate_report: false,
      addRemark: false,
      addedRemark: "",
      task: {
        project_name: null,
        plan_name: null,
        task_name: null,
        type: ["missing screws", "rusting"],
      },
      user: {},
      type: "",
      progress: 0,
      selectedObj: [],
      objectsDetected: 0,
      showDownloadPopup: false,
      loader: true,
      services: [],
      isDetectionDirty: false,
      obj: [],
      data: [],
      report: [],
      report1: [],
      objects: [],
      projects: [],
      colors: [],
      showBackIcon: true,
      cancel: false,
      loading: true,
      objectsDetected: 0,
      sidebarMode: this.props.location.state
        ? this.props.location.state.sidebarMode
        : "shrunk",
      showData: [],
      reportheader: {},
      reportTitle: '',
      reportSubTitle: '',
      company_logo: '',
      company_name: '',
      editHeader: false,
      company_logo1: undefined,
      titleEdit: false,
      subTitleEdit: false,
      reportData: [
        { title: 'Analysis Report', visibleInReport: true, reportView: 'List Small' },
        { title: 'Detailed Analysis', visibleInReport: true, reportView: 'List Small' },
        { title: 'Severity-1 Detections', visibleInReport: true, reportView: 'List Small' },
        { title: 'Severity-2 Detections', visibleInReport: true, reportView: 'List Small' },
        { title: 'Severity-3 Detections', visibleInReport: true, reportView: 'List Small' },
        { title: 'Severity-4 Detections', visibleInReport: true, reportView: 'List Small' },
        { title: 'Severity-5 Detections', visibleInReport: true, reportView: 'List Small' }
      ],
      severity1Data: [],
      severity2Data: [],
      severity3Data: [],
      severity4Data: [],
      severity5Data: [],
      noneSeverityData: [],
      objectsToDisplay: [],
      reportViews: [{ value: 'List Small' }, { value: 'List Large' }, { value: 'Grid Small' }, { value: 'Grid Large' }],
      showTags: false, // to show/hide labels of detections
      totalObjectsDetected: 0,
      pieData: null,
      imageLoad: 0,
      selectedSeverity: "All",
      severityToSelect: [{ label: AppConstants.SEVIRITY._1 }, { label: AppConstants.SEVIRITY._2 }, { label: AppConstants.SEVIRITY._3 }, { label: AppConstants.SEVIRITY._4 }, { label: AppConstants.SEVIRITY._5 }, { label: AppConstants.SEVIRITY.NONE }, { label: 'All' }],
      allData: [],
      showAnnotation: true,
      showProcessPopup: false,
      processMessage: null,
      mainData: [],
      userperpage: 8,
      currentpage: 1,
      tempPageNo: undefined,
      counts: 0,
      currentPageData: {},
      refreshCanvas: '',
      total_storage: 0,
      total_resolution: 0,
      report_generated: '',
      report_modified: '',
      downloadFlag: '',
      generate_request: '',
      minutesDiff: 0,
      generation_status: false,
      downloadWarning: false,
      downloadProgress: false,
      imagesProcessed: 0,
      firstTimeDownload: false,
      windMillTask: false,
      filetype: '',
      isEdit: false,
      filterWarningPopup: false,
      deleteAnotationPopup: false,
      selectedRegionToDelete: undefined,
      updateReportAfterSave: false,
      // hideSeverityFilter: false,
    };
  }

  // to load tiff image 
  loadTiffImageSrc1 = async () => {
    let { showData, user, task } = this.state
    for (let i = 0; i < showData.length; i++) {
      if (showData[i].image) {
        showData[i].loading = true
        let name = showData[i].image.toLowerCase()
        if ((name.indexOf(".tif")) !== -1 && !(this.props.location.state.task.linked_map)) {
          // for thumbnails
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'arraybuffer';
          let url = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${showData[i].image}?${this.state.permissions?.st}`
          xhr.open('GET', url);
          xhr.onload = function (e) {
            var arraybuffer = e.target.response
            Tiff.initialize({
              TOTAL_MEMORY: 16777216 * 10
            });
            var tiff = new Tiff({
              buffer: arraybuffer
            })
            var dataUrl = tiff.toDataURL();
            let url = e.target.responseURL
            var index = 0
            showData.map((detection, j) => {
              if (detection && detection.image && url.indexOf(encodeURIComponent(detection.image)) != -1) {
                index = j
              }
            })
            showData[index].imageSrc = dataUrl   // to insert url at specific position
            this.setState((state) => ({
              ...state,
              showData
            }))
          }.bind(this);
          xhr.send()
        }
        else {
          showData[i].imageSrc = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${showData[i].image}?${this.state.permissions?.st}`   // to insert url at specific position
          this.setState((state) => ({
            ...state,
            showData
          }))
        }
      }
    }
  }

  // to load tiff image  current not in use
  loadTiffImageSrc = async () => {
    let { showData, user, task, currentPageData } = this.state
    // for (let i = 0; i < showData.length; i++) {
    if (currentPageData.image) {
      currentPageData.loading = true
      let name = currentPageData.image.toLowerCase()
      if ((name.indexOf(".tif")) !== -1) {
        // for thumbnails
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        let url = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${currentPageData.image}?${this.state.permissions?.st}`
        xhr.open('GET', url);
        xhr.onload = function (e) {
          var arraybuffer = e.target.response
          Tiff.initialize({
            TOTAL_MEMORY: 16777216 * 10
          });
          var tiff = new Tiff({
            buffer: arraybuffer
          })
          var dataUrl = tiff.toDataURL();
          currentPageData.imageSrc = dataUrl   // to insert url at specific position
          this.setState((state) => ({
            ...state,
            currentPageData
          }))
        }.bind(this);
        xhr.send()
      }
      else {
        currentPageData.imageSrc = `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${currentPageData.image}?${this.state.permissions?.st}`   // to insert url at specific position
        this.setState((state) => ({
          ...state,
          currentPageData
        }))
      }
    }
    // }
  }
  calculateBoundingBoxSize(polygonPoints) {
    if (polygonPoints.length === 0) {
      return { width: 0, height: 0 };
    }

    let minX = polygonPoints[0][0];
    let maxX = polygonPoints[0][0];
    let minY = polygonPoints[0][1];
    let maxY = polygonPoints[0][1];

    // Find the minimum and maximum x and y coordinates
    for (let i = 0; i < polygonPoints.length; i++) {
      const [x, y] = polygonPoints[i];
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    }

    const width = maxX - minX;
    const height = maxY - minY;
    return { width, height };
  }
  createPageDataArray = () => {
    let { user, task } = this.state
    let data = this.state.showData
    let mainData = [{}, {}]
    let tableData = []
    let totalTableData = []

    if (this.state.windMillTask) {
      for (let z = 0; z < data.length; z++) {
        for (let x = 0; x < data[z].cropRegions.length; x++) {
          let bboxwidth = 0;
          let bboxheight = 0;
          if (data[z].cropRegions[x].innerRegion?.type === "polygon") {
            const { width, height } = this.calculateBoundingBoxSize(data[z].cropRegions[x].innerRegion.points)
            bboxwidth = width
            bboxheight = height
          }
          if (data[z].imgData.windmillData?.blade) {

            tableData.push({
              blade: data[z].imgData.windmillData.blade,
              side: data[z].imgData.windmillData.side,
              issues: data[z].cropRegions[x].innerRegion.className,
              severity: data[z].cropRegions[x].innerRegion.data.severity ? data[z].cropRegions[x].innerRegion.data.severity == 1 ? 'Normal'
                : data[z].cropRegions[x].innerRegion.data.severity == 2 ? 'Medium'
                  : data[z].cropRegions[x].innerRegion.data.severity == 3 ? 'High'
                    : data[z].cropRegions[x].innerRegion.data.severity == 4 ? 'Higher'
                      : data[z].cropRegions[x].innerRegion.data.severity == 5 ? 'Very High' : '-'
                : '-',
              size: data[z].cropRegions[x].innerRegion?.type === "polygon" ? `${Math.round(bboxwidth * Number(data[z].imgData.width))} x ${Math.round(bboxheight * Number(data[z].imgData.height))} px` : (isNaN(Math.round(data[z].cropRegions[x].innerRegion.w * Number(data[z].imgData.width))) || isNaN(Math.round(data[z].cropRegions[x].innerRegion.h * Number(data[z].imgData.height)))) ? "-" : `${Math.round(data[z].cropRegions[x].innerRegion.w * Number(data[z].imgData.width))} x ${Math.round(data[z].cropRegions[x].innerRegion.h * Number(data[z].imgData.height))} px`,
              alt: (Number(data[z].imgData.altitude)).toFixed(2)
            })

            if (tableData.length === 10 || z == data.length - 1 && x == data[z].cropRegions.length - 1) {
              mainData.push({
                tableRecords: tableData,
                tableRecordShow: true,
                showImage: false,
                // loading: true
              })
              tableData = []
            }
            totalTableData.push({
              blade: data[z].imgData.windmillData.blade,
              side: data[z].imgData.windmillData.side,
              issues: data[z].cropRegions[x].innerRegion.className,
              severity: data[z].cropRegions[x].innerRegion.data.severity ? data[z].cropRegions[x].innerRegion.data.severity == 1 ? 'Normal'
                : data[z].cropRegions[x].innerRegion.data.severity == 2 ? 'Medium'
                  : data[z].cropRegions[x].innerRegion.data.severity == 3 ? 'High'
                    : data[z].cropRegions[x].innerRegion.data.severity == 4 ? 'Higher'
                      : data[z].cropRegions[x].innerRegion.data.severity == 5 ? 'Very High' : '-'
                : '-',
              size: data[z].cropRegions[x].innerRegion.type === "polygon" ? `${Math.round(bboxwidth * Number(data[z].imgData.width))} x ${Math.round(bboxheight * Number(data[z].imgData.height))} px` : (isNaN(Math.round(data[z].cropRegions[x].innerRegion.w * Number(data[z].imgData.width))) || isNaN(Math.round(data[z].cropRegions[x].innerRegion.h * Number(data[z].imgData.height)))) ? "-" : `${Math.round(data[z].cropRegions[x].innerRegion.w * Number(data[z].imgData.width))} x ${Math.round(data[z].cropRegions[x].innerRegion.h * Number(data[z].imgData.height))} px`,
              alt: (Number(data[z].imgData.altitude)).toFixed(2)
            })
          }

        }
      }
      this.setState({ totalTableData })
    }

    for (let i = 0; i < data.length; i++) {
      let cropRegions = []
      for (let j = 0; j < data[i].cropRegions.length; j++) {
        if (j < 2) {
          data[i].cropRegions[j].index = j + 1
          cropRegions.push(data[i].cropRegions[j])
          if (j === 1 || j == data[i].cropRegions.length - 1) {
            let pageObj = {
              image: data[i].imgData.file_name,
              imageSrc: `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${data[i].imgData.file_name}?${this.state.permissions?.st}`,
              showImage: true,
              regions: data[i].regions,
              imgData: data[i].imgData,
              cropRegions: cropRegions,
              tags: data[i].tags,
              loading: true
            }
            mainData.push(pageObj)
            cropRegions = []
          }
        } else if (j > 1) {
          data[i].cropRegions[j].index = j + 1
          cropRegions.push(data[i].cropRegions[j])
          if (j % 4 === 1 || j === data[i].cropRegions.length - 1) {
            let pageObj = {
              image: data[i].imgData.file_name,
              imageSrc: `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${data[i].imgData.file_name}?${this.state.permissions?.st}`,
              showImage: false,
              imgData: data[i].imgData,
              cropRegions: cropRegions,
              tags: data[i].tags,
              loading: true
            }
            mainData.push(pageObj)
            cropRegions = []
          }
        }
      }
    }
    this.setState((state) => ({
      ...state,
      mainData: mainData,
    }), () => {
      this.changepage(this.state.currentpage > (this.state.mainData.length - 1) ? (this.state.mainData.length - 1) : this.state.tempPageNo || 1)
    })
  }

  calculateDateDiff = () => {
    if (this.state.generated_date == null) {
      let date = new Date(0).toISOString()
      let diff = date - this.state.generate_request
      let diff1 = Math.floor(diff / 60e3)

      this.setState({
        minutesDiff: diff1
      })
    }
  }

  firstpage = () => {

    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: 1,
        currentPageData: {},
        refreshCanvas: false
      }), () => {
        this.setState({ refreshCanvas: true })
      });
    } else {
    }
  }
  // Prev Page function
  prevpage = () => {
    if (this.state.currentpage === 2 && this.state.selectedSeverity === "All") {
      this.setState({ currentpage: this.state.currentpage - 1, tempPageNo: undefined }, () => {
        this.getSummaryDetails()
      })
    }
    else if (this.state.currentpage > 1) {
      let temp = this.state.mainData[this.state.currentpage - 1]
      temp.loading = true
      this.setState((state) => ({
        ...state,
        currentpage: this.state.currentpage - 1,
        currentPageData: temp,
        refreshCanvas: false,
      }), () => {
        this.setState({ refreshCanvas: true })

      });
    }


  }
  // Next page Function
  nextpage = () => {
    if (this.state.currentpage < this.state.mainData.length - 1) {
      let temp = this.state.mainData[this.state.currentpage + 1]
      temp.loading = true
      this.setState((state) => ({
        ...state,
        currentpage: this.state.currentpage + 1,
        currentPageData: temp,
        refreshCanvas: false
      }), () => {
        this.setState({ refreshCanvas: true })
      });
    } else {
    }
  }
  // Get Last Page Function Start here----------------------
  lastpage = () => {
    if (this.state.currentpage < this.state.mainData.length - 1) {
      this.setState((state) => ({
        ...state,
        currentpage: this.state.mainData.length - 1,
        currentPageData: this.state.mainData[this.state.mainData.length - 1],
        refreshCanvas: false
      }), () => {
        this.setState({ refreshCanvas: true })
      });
    } else {
    }
  }

  changepage(num) {
    if (isNaN(num)) {
      return null;
    } else {
      if (num === 1) {
        this.setState((state) => ({
          ...state,
          currentpage: 1,
          currentPageData: {},
          refreshCanvas: false
        }), () => {
          this.setState({ refreshCanvas: true })
        });
      } else if (num !== 0 && num < this.state.mainData.length) {
        let temp = this.state.mainData[num]
        temp.loading = false
        this.setState((state) => ({
          ...state,
          currentpage: num,
          currentPageData: temp,
          refreshCanvas: false,
          tempPageNo: undefined,
        }), () => {
          this.setState({ refreshCanvas: true })
        });
      }
    }
  }

  setColors = () => {
    let colors = []
    this.state.task.type.forEach((element, i) => {
      let c = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
      colors.push(c)
    });
    this.setState({ colors })
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };
  updateReportHeader = () => {
    API.updateReportHeader(this.state.reportTitle, this.state.reportSubTitle, this.state.company_name, this.state.reportheader.id).then((data) => {
      if (data.status == 200) {
        this.setState((state) => ({
          ...state,
          editHeader: false,
          generation_status: true
        }))
        this.getReportHeader();
      } else {
        this.getReportHeader();
      }
    })
  }

  getReportHeader = () => {
    let task_id = this.state.task.task_id
    let report_type = 'Defect'
    API.getReportHeader(task_id, report_type).then(async (data) => {
      if (data.data == null) {
        this.addReportHeader();
      } else {

        let profileURL = ""
        if (this.state.task.sharedBy && this.state.task.sharedBy.isShared) {
          profileURL = `/images/${this.state.task.sharedBy.sharedBy.first_name[0].toLowerCase()}.PNG`
        } else if (data.data.company_logo != null && data.data.company_logo.includes('pdrlImage')) {
          profileURL = `${data.data.companyLogo}`
        }
        else if (data.data.company_logo != null && data.data.company_logo?.split("?")[0] != 'null') { //normal null    detection null?st
          profileURL = data.data.company_logo
        } else if (this.state.avatar_url && this.state.avatar_url.includes('pdrlImage')) {
          profileURL = `${this.state.avatar_url}`
        }
        else if (this.state.avatar_url != null && this.state.avatar_url.includes('http')) {
          profileURL = this.state.avatar_url
          if (!(await checkURLExist(`${profileURL}?${this.state.permissions.st}`))) {
            profileURL = "";
          }
        } else {
          profileURL = ""
        }

        this.setState((state) => ({
          ...state,
          reportheader: data.data,
          reportTitle: data.data.title,
          reportSubTitle: data.data.subtitle,
          company_logo: profileURL?.split("?")[0],
          company_name: this.state.task.sharedBy && this.state.task.sharedBy.isShared ? `${this.state.task.sharedBy.sharedBy.first_name} ${this.state.task.sharedBy.sharedBy.last_name}` : data.data.name,
          report_generated: data.data.generated_date,
          report_modified: data.data.modified_date,
          generate_request: data.data.generate_request
        }), () => {
          this.calculateDateDiff()
        })
      }
    }, (e) => {
      console.log(e);
    })
  }

  addReportHeader = () => {
    let task_id = this.state.task.task_id
    let report_type = 'Defect'
    let title = this.state.task.task_name
    let subtitle = this.state.task.project_name
    let company_logo = null
    API.addReportHeader(task_id, report_type, title, company_logo, subtitle).then((data) => {
      if (data.status == 201) {
        this.getReportHeader();
      } else {
        this.setState((state) => ({
          ...state,
          reportTitle: this.state.task.task_name,
          reportSubTitle: this.state.task.project_name,
          company_logo: this.state.avatar_url ? `${this.state.avatar_url}` : `/images/${this.state.user.first_name[0].toLowerCase()}.PNG`,
          company_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        }), () => {
        })
      }
    })
  }
  findBoundingBoxCoordinates(polygonPoints) {
    if (polygonPoints.length === 0) {
      return null;
    }

    let minX = polygonPoints[0][0];
    let maxX = polygonPoints[0][0];
    let minY = polygonPoints[0][1];
    let maxY = polygonPoints[0][1];

    for (let i = 1; i < polygonPoints.length; i++) {
      const [x, y] = polygonPoints[i];
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    }

    return [
      minX, minY, maxX, maxY
    ];
  }
  convertRegion = (line, imgData, type) => {
    if (type === "polygon") {
      let co_ordinates = line.map(point => [parseInt(parseFloat(point[0]) * imgData.width), parseInt(parseFloat(point[1]) * imgData.height)])

      let boundingboxCoordinates = this.findBoundingBoxCoordinates(line)
      boundingboxCoordinates[0] = parseInt(parseFloat(boundingboxCoordinates[0]) * imgData.width)
      boundingboxCoordinates[1] = parseInt(parseFloat(boundingboxCoordinates[1]) * imgData.height)
      boundingboxCoordinates[2] = parseInt(parseFloat(boundingboxCoordinates[2]) * imgData.width)
      boundingboxCoordinates[3] = parseInt(parseFloat(boundingboxCoordinates[3]) * imgData.height)

      //outer region coordiante calculation
      let xmin = boundingboxCoordinates[0] - 80
      let ymin = boundingboxCoordinates[1] - 80
      let xmax = boundingboxCoordinates[2] + 80
      let ymax = boundingboxCoordinates[3] + 80
      if (xmin < 0) {
        xmin = 0
        xmax = xmax - xmin
      }
      if (xmax > imgData.width) {
        let diff = imgData.width - xmax
        xmin = xmin - diff
        xmax = imgData.width
      }
      if (ymin < 0) {
        ymin = 0
        ymax = ymax - ymin
      }
      if (ymax > imgData.height) {
        let diff = imgData.height - ymax
        ymin = ymin - diff
        ymax = imgData.height
      }
      let h = ymax - ymin
      let w = xmax - xmin

      // if annotation is horizontal, , update the y co-ordinates such that height=width
      if (w > h) {
        let diff = w - h

        if (ymin == 0 && ymax != imgData.height) {
          ymax = parseInt(ymax + (diff))
        }
        else if (ymin != 0 && ymax == imgData.height) {
          ymin = parseInt(ymin - (diff))
        }
        else {
          ymax = parseInt(ymax + (diff / 2))
          ymin = parseInt(ymin - (diff / 2))
        }
        h = ymax - ymin

      } else {
        // if annotation is vertical , update the x co-ordinates such that height=width
        let diff = h - w

        if (xmin == 0 && xmax != imgData.width) {
          xmax = parseInt(xmax + (diff))
        }
        else if (xmin != 0 && xmax == imgData.width) {
          xmin = parseInt(xmin - (diff))
        }
        else {
          xmax = parseInt(xmax + (diff / 2))
          xmin = parseInt(xmin - (diff / 2))
        }
        w = xmax - xmin
      }
      const [minX, minY, maxX, maxY] = boundingboxCoordinates
      let outerRegion = { y: ymin, x: xmin, h, w }
      let annotationRegion = { points: co_ordinates, boundingbox: { x: minX, y: minY, w: maxX - minX, h: maxY - minY } }
      return {
        outerRegion,
        annotationRegion,
        innerRegion: {
          points: line.map(point => [parseFloat(point[0]), parseFloat(point[1])])
        },
      }

    }
    else {

      let co_ordinates = { ymin: parseInt(parseFloat(line[0]) * imgData.height), xmin: parseInt(parseFloat(line[1]) * imgData.width), ymax: parseInt(parseFloat(line[2]) * imgData.height), xmax: parseInt(parseFloat(line[3]) * imgData.width) }

      let ymin = co_ordinates.ymin - 80
      let xmin = co_ordinates.xmin - 80
      let ymax = co_ordinates.ymax + 80
      let xmax = co_ordinates.xmax + 80

      if (xmin < 0) {
        xmin = 0
        xmax = xmax - xmin
      }
      if (xmax > imgData.width) {
        let diff = imgData.width - xmax
        xmin = xmin - diff
        xmax = imgData.width
      }
      if (ymin < 0) {
        ymin = 0
        ymax = ymax - ymin
      }
      if (ymax > imgData.height) {
        let diff = imgData.height - ymax
        ymin = ymin - diff
        ymax = imgData.height
      }
      let h = ymax - ymin
      let w = xmax - xmin

      if (w > h) {
        let diff = w - h

        if (ymin == 0 && ymax != imgData.height) {
          ymax = parseInt(ymax + (diff))
        }
        else if (ymin != 0 && ymax == imgData.height) {
          ymin = parseInt(ymin - (diff))
        }
        else {
          ymax = parseInt(ymax + (diff / 2))
          ymin = parseInt(ymin - (diff / 2))
        }
        h = ymax - ymin

      } else {
        let diff = h - w

        if (xmin == 0 && xmax != imgData.width) {
          xmax = parseInt(xmax + (diff))
        }
        else if (xmin != 0 && xmax == imgData.width) {
          xmin = parseInt(xmin - (diff))
        }
        else {
          xmax = parseInt(xmax + (diff / 2))
          xmin = parseInt(xmin - (diff / 2))
        }
        w = xmax - xmin
      }
      let outerRegion = { y: ymin, x: xmin, h, w }
      let annotationRegion = { y: co_ordinates.ymin, x: co_ordinates.xmin, h: co_ordinates.ymax - co_ordinates.ymin, w: co_ordinates.xmax - co_ordinates.xmin }
      return {
        outerRegion,
        annotationRegion,
        innerRegion: {
          y: parseFloat(line[0]),
          x: parseFloat(line[1]),
          h: (parseFloat(line[2]) - parseFloat(line[0])),
          w: (parseFloat(line[3]) - parseFloat(line[1])),
        },
      }
    }
  }

  getSummaryDetails = async (severityLevel) => {
    this.setState({ isReportLoading: true, })
    let { task, user } = this.state
    let severity1Data = []
    let severity2Data = []
    let severity3Data = []
    let severity4Data = []
    let severity5Data = []

    let noneSeverityData = []
    API.getDetections(task.task_id, task.type).then(
      (data) => {
        if (data.length == 0) {
          this.setState((state) => ({
            ...state,
            loading: false,
          }));
        } else {
          let report = data.ret

          let imageData = data.image_data
          let showData = []
          let totalObjectsDetected = 0, sev1total = 0, sev3total = 0, sev2total = 0, sev4total = 0, sev5total = 0
          if (report.length > 0) {
            for (let j = 0; j < report.length; j++) {

              let imgData = imageData.find((data) => { return data.file_name == report[j].image })
              report[j].imgData = imgData
              report[j].tags = task.collection_tags

              let regions = [], count = 1, cropRegions = []
              let ele = data.ret[j]
              let imageSrc = report[j].imageSrc || `${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${report[j].image}?${this.state.permissions?.st}`
              let sev1 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
              let sev2 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
              let sev3 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
              let sev4 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
              let sev5 = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }

              let nele = { image: report[j].image, imgData: report[j].imgData, tags: report[j].tags, regions: [], cropRegions: [], imageSrc }
              let sev1count = 0, sev2count = 0, sev3count = 0, sev4count = 0, sev5count = 0, noneCount = 0;
              let objectsDetected = 0;
              for (let i = 0; i < this.state.task.type.length; i++) {
                let obj = this.state.task.type[i]
                let res = report[j].result.detections[obj] ? report[j].result.detections[obj].data : []
                let tmp = {}
                if (res.length > 0) {
                  for (let m = 0; m < res.length; m++) {
                    let dt = res[m]
                    if (dt.poly.length === 0 || dt.poly[0] === null || dt.poly[0][0] === null) continue;
                    if (dt.type === "box" || dt.type === "Box" || dt.type === "polygon" || dt.type === "Polygon" || dt.type === undefined) {
                      if (!dt.id) dt.id = uuid();
                      totalObjectsDetected += 1
                      objectsDetected += 1
                      let reg = this.props.location.state.task.linked_map ? { innerRegion: dt } : dt.type === "polygon" ? { ...this.convertRegion(dt.poly, report[j].imgData, dt.type), regionId: dt.id, regionClass: dt.className } : { ...this.convertRegion(dt.poly, report[j].imgData), regionId: dt.id, regionClass: dt.className }
                      tmp = reg.innerRegion
                      const severity = dt.severity || dt.data?.severity
                      tmp.data = { severity: severity, note: dt.note || dt.data?.note, datatype: obj, percentage: dt.percentage, id: m + 1, }
                      tmp.id = uuid()
                      tmp.type = (dt.type === "polygon" || dt.type === "Polygon") ? "polygon" : "box"
                      tmp.color = "#ff0000"
                      tmp.index = count
                      tmp.className = obj
                      reg.regionClass = reg.regionClass ? reg.regionClass : tmp.className
                      regions.push(tmp);
                      cropRegions.push(reg)
                      if (severity == '5') {
                        sev5.regions.push(tmp)
                        sev5count++;
                        sev5.cropRegions.push(reg)
                      } else if (severity == '4') {
                        sev4.regions.push(tmp)
                        sev4count++;
                        sev4.cropRegions.push(reg)
                      } else if (severity == '3') {
                        sev3.regions.push(tmp)
                        sev3count++;
                        sev3.cropRegions.push(reg)
                      } else if (severity == '2') {
                        sev2.regions.push(tmp)
                        sev2count++
                        sev2.cropRegions.push(reg)
                      } else if (severity == '1') {
                        sev1.regions.push(tmp)
                        sev1count++
                        sev1.cropRegions.push(reg)
                      } else {
                        nele.regions.push(tmp)
                        noneCount++
                        nele.cropRegions.push(reg)
                      }
                    }
                    count++
                  }
                }
                report[j].regions = regions
                report[j].cropRegions = cropRegions
              }
              if (sev1count > 0) {
                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Normal' : 'Normal'
                sev1.tags = sev1.tags && sev1.tags.length > 0 ? sev1.tags + ' ,Normal' : 'Normal'

                sev1.count = sev1count
                sev1.userId = report[j].userId
                sev1.task = report[j].task
                sev1total += sev1count
                severity1Data.push(sev1)
              }

              if (sev2count > 0) {
                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Medium' : 'Medium'
                sev2.tags = sev2.tags && sev2.tags.length > 0 ? sev2.tags + ' ,Medium' : 'Medium'

                sev2.count = sev2count
                sev2.userId = report[j].userId
                sev2.task = report[j].task
                sev2total += sev2count
                severity2Data.push(sev2)
              }
              if (sev3count > 0) {
                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,High' : 'High'
                sev3.tags = sev3.tags && sev3.tags.length > 0 ? sev3.tags + ' ,High' : 'High'

                sev3.count = sev3count
                sev3.userId = report[j].userId
                sev3.task = report[j].task
                sev3total += sev3count
                severity3Data.push(sev3);
              }
              if (sev4count > 0) {
                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Higher' : 'Higher'
                sev4.tags = sev4.tags && sev4.tags.length > 0 ? sev4.tags + ' ,Higher' : 'Higher'

                sev4.count = sev4count
                sev4.userId = report[j].userId
                sev4.task = report[j].task
                sev4total += sev4count
                severity4Data.push(sev4);
              }
              if (sev5count > 0) {
                report[j].tags = report[j].tags && report[j].tags.length > 0 ? report[j].tags + ' ,Very High' : 'Very High'
                sev5.tags = sev5.tags && sev5.tags.length > 0 ? sev5.tags + ' ,Very High' : 'Very High'

                sev5.count = sev5count
                sev5.userId = report[j].userId
                sev5.task = report[j].task
                sev5total += sev5count
                severity5Data.push(sev5);
              }
              if (noneCount > 0) {
                nele.count = noneCount
                nele.userId = report[j].userId
                nele.task = report[j].task
                noneSeverityData.push(nele)
              }
              report[j].objectsDetected = objectsDetected;
              if (regions.length > 0)
                showData.push(report[j])

            }
          }
          let sev3count = 0
          const pieData = {
            labels: [`1`, `2`, `3`, `4`, `5`, `None`],
            datasets: [
              {
                label: 'Detection analysis',
                data: [sev1total, sev2total, sev3total, sev4total, sev5total, totalObjectsDetected - (sev1total + sev2total + sev3total + sev4total + sev5total)],
                backgroundColor: graphColours,
                borderColor: graphColours,
                borderWidth: 2,
              }
            ]
          }
          this.setState(
            (state) => ({
              ...state,
              report,
              showData,
              allData: showData,
              severity3Data,
              severity1Data,
              severity2Data,
              severity4Data,
              severity5Data,
              noneSeverityData,
              windMillTask: data.isWindMillData,
              pieData,
              sev3total,
              sev1total,
              sev2total,
              sev4total,
              sev5total,
              totalObjectsDetected,
              report1: data.image_data.sort((a, b) => a.file_name > b.file_name ? 1 : -1),
              imagesProcessed: showData.length
            }), () => {
              this.setState({ loading: false, isReportLoading: false, classes: showData[0]?.result?.objects }, () => {
                if (severityLevel) this.setState({
                  showAnnotation: false,
                  report_generated: null,
                  generation_status: true,
                })
                if (severityLevel === "1") this.severityLevelList(AppConstants.SEVIRITY._1, this.state.severity1Data, this.state.severity1Data.length, true)
                else if (severityLevel === "2") this.severityLevelList(AppConstants.SEVIRITY._2, this.state.severity2Data, this.state.severity2Data.length, true)
                else if (severityLevel === "3") this.severityLevelList(AppConstants.SEVIRITY._3, this.state.severity3Data, this.state.severity3Data.length, true)
                else if (severityLevel === "4") this.severityLevelList(AppConstants.SEVIRITY._4, this.state.severity4Data, this.state.severity4Data.length, true)
                else if (severityLevel === "5") this.severityLevelList(AppConstants.SEVIRITY._5, this.state.severity5Data, this.state.severity5Data.length, true)
                else if (severityLevel === "None") this.severityLevelList("None", this.state.noneSeverityData, this.state.noneSeverityData.length, true)
                else if (severityLevel === "All") this.severityLevelList("All", this.state.allData, this.state.allData.length, true)

              })
              this.loadTiffImageSrc1()
              this.createPageDataArray()
              this.calculateImageData()

            });
        }
      },
      (e) => {

        this.setState((state) => ({
          ...state,
          report: [],
          report1: [],
          loading: false,
        }));
      }
    );
  };

  calculateImageData = () => {
    let data = this.state.showData
    var total_storage = 0
    var total_resolution = 0
    for (let i = 0; i < data.length; i++) {
      total_storage = total_storage + Number(data[i].imgData.file_size)
      total_resolution = total_resolution + Number(data[i].imgData.resolution)
    }
    this.setState((state) => ({
      ...state,
      total_storage: total_storage.toFixed(2),
      total_resolution: total_resolution.toFixed(2)
    }))
  }

  handleRemark = (e) => {
    this.setState({
      addedRemark: e.target.value
    })
  }
  addRegionToSeverityArr = (foundCropRegion, foundRegion, type, updatedData, regionId, innerRegionId) => {

    if (foundRegion && foundCropRegion) {
      foundCropRegion = foundCropRegion.map(cr => {
        if (cr.regionId === regionId) {
          return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData } } }
        } else return cr;
      })
      foundRegion = foundRegion.map(reg => {
        if (reg?.id === innerRegionId) {
          return { ...reg, data: { ...reg?.data, [type]: updatedData } }
        } else return reg
      })
      if (updatedData === "1") {
        this.setState({
          severity1Data: this.state.severity1Data.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      } else if (updatedData === "2") {
        this.setState({
          severity2Data: this.state.severity2Data.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      } else if (updatedData === "3") {
        this.setState({
          severity3Data: this.state.severity3Data.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      } else if (updatedData === "4") {
        this.setState({
          severity4Data: this.state.severity4Data.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      } else if (updatedData === "5") {
        this.setState({
          severity5Data: this.state.severity5Data.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      } else if (updatedData === "None") {
        this.setState({
          noneSeverityData: this.state.noneSeverityData.map(sd => {
            if (sd.cropRegions.find(cr => cr.regionId === regionId)) {
              return { ...sd, cropRegions: [...sd.cropRegions, foundCropRegion], regions: [...sd.regions, foundRegion] }
            } else return sd;
          })
        })
      }
    }
  }

  updateRemark = async (updatedData, region, type) => {
    const newShowData = await Promise.all(this.state.showData.map(sd => {
      return new Promise(async (resolve, reject) => {
        let callAPI = false;
        let updateResult = {
          ...sd.result, detections: {
            ...sd.result.detections, [region.regionClass]: {
              ...sd.result.detections[region.regionClass], data: sd.result.detections[region.regionClass]?.data?.map(dt => {
                if (dt.id === region.regionId) {
                  callAPI = true;
                  return { ...dt, data: { ...dt.data, [type]: updatedData }, [type]: updatedData }
                } else return dt
              })
            }
          }
        }
        callAPI && await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
          this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage }), () => { })
        })
        let updatedRegions = sd.regions.map(reg => {
          if (reg.id === region.innerRegion.id) {
            return { ...reg, data: { ...reg.data, [type]: updatedData } }
          } else return reg
        })
        let newCropRegions = sd.cropRegions.map(cr => {
          if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData } } }
          else return cr;
        })
        resolve({
          ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
        })
      })
    }))
    let updatedPageData = {
      ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.map(cr => {
        if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, data: { ...cr.innerRegion.data, [type]: updatedData }, }, }
        else return cr;
      }), regions: this.state.currentPageData?.regions?.map(reg => {
        if (reg.id === region.innerRegion.id) return { ...reg, data: { ...reg.data, [type]: updatedData } }
        else return reg;
      })
    }

    this.setState({
      showData: newShowData, currentPageData: updatedPageData,
      mainData: this.state.mainData.map((cr, i) => {
        if (i === this.state.currentpage) {
          return updatedPageData
        } else return cr
      })
      , allData: newShowData
    }, () => {
    })
  }
  deleteAnnotation = async (region) => {
    this.setState({ deleteAnotationPopup: false, isReportLoading: true })
    let newShowData = await Promise.all(this.state.showData.map(async sd => {
      return new Promise(async (resolve, reject) => {

        let callAPI = sd.result.detections[region.regionClass]?.data.find(reg => reg.id === region.regionId) ? true : false;
        let updateResult = {
          ...sd.result, detections: {
            ...sd.result.detections, [region.regionClass]: {
              ...sd.result.detections[region.regionClass], data: sd.result.detections[region.regionClass]?.data.filter(dt => dt.id !== region.regionId)
            }
          }
        }
        callAPI && await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
          this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage, selectedRegionToDelete: undefined, isReportLoading: false }), () => {
          })
        })
        let updatedRegions = sd.regions.filter(reg => reg.id !== region.innerRegion.id)
        let newCropRegions = sd.cropRegions.filter(cr => cr.regionId !== region.regionId)
        resolve({
          ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
        })
      })
    }))
    let updatedPageData = {
      ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.filter(cr => cr.regionId !== region.regionId), regions: this.state.currentPageData?.regions?.filter(reg => reg.id !== region.innerRegion.id)
    }

    this.setState({
      showData: newShowData, currentPageData: updatedPageData,
      mainData: this.state.mainData.map((cr, i) => {
        if (i === this.state.currentpage) {
          return updatedPageData
        } else return cr
      })
      , allData: newShowData, tempPageNo: this.state.currentpage, selectedRegionToDelete: undefined, isReportLoading: false
    }, () => {
    })
  }


  updateClasses = async (newClassName, oldClassName, region) => {
    await this.updateRemark("None", region, 'severity')
    let newShowData = await Promise.all(this.state.showData.map(async sd => {
      return new Promise(async (resolve, reject) => {

        let oldDataArr = sd.result.detections[oldClassName]?.data.filter(det => det.id === region.regionId);
        if (oldDataArr.length > 0) {

          const oldData = sd.result.detections[oldClassName].data?.find(det => det.id === region.regionId)
          let updateResult = {
            ...sd.result, detections: {
              ...sd.result.detections, [oldClassName]: {
                ...sd.result.detections[oldClassName], data: sd.result.detections[oldClassName]?.data.filter(det => det.id !== region.regionId), count: sd.result.detections[oldClassName]?.data.filter(det => det.id !== region.regionId).length,
              },
              [newClassName]: { ...sd.result.detections[newClassName], data: [...sd.result.detections[newClassName].data, { ...oldData, data: { ...oldData.data, datatype: newClassName, }, className: newClassName }], count: sd.result.detections[newClassName].count + 1 }
            }
          }
          await API.saveDetectionResult(this.state.task.task_id, sd.image, updateResult).then(res => {
            this.setState((state) => ({ ...state, tempPageNo: this.state.currentpage }), () => { })
          })
          let updatedRegions = sd.regions.map(reg => {
            if (reg.id === region.innerRegion.id) {
              return { ...reg, className: newClassName, data: { ...reg.data, datatype: newClassName, } }
            } else return reg
          })
          let newCropRegions = sd.cropRegions.map(cr => {
            if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, className: newClassName, data: { ...cr.innerRegion.data, datatype: newClassName, } }, regionClass: newClassName }
            else return cr;
          })
          resolve({
            ...sd, result: updateResult, regions: updatedRegions, cropRegions: newCropRegions
          })
        }
        else resolve(sd)
      })
    }))
    let updatedPageData = {
      ...this.state.currentPageData, cropRegions: this.state.currentPageData.cropRegions.map(cr => {
        if (cr.regionId === region.regionId) return { ...cr, innerRegion: { ...cr.innerRegion, className: newClassName, data: { ...cr.innerRegion.data, datatype: newClassName, }, }, regionClass: newClassName }
        else return cr;
      }), regions: this.state.currentPageData?.regions?.map(reg => {
        if (reg.id === region.innerRegion.id) return { ...reg, className: newClassName, data: { ...reg.data, datatype: newClassName, } }
        else return reg;
      })
    }

    this.setState({
      showData: newShowData, currentPageData: updatedPageData,
      mainData: this.state.mainData.map((cr, i) => {
        if (i === this.state.currentpage) {
          return updatedPageData
        } else return cr
      })
      , allData: newShowData
    }, () => {
    })
  }

  componentWillMount() {
    ChartJS.register(BarElement, CategoryScale,
      ArcElement,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      Filler
    );
    document.title = "PicStork Report";
    getFeaturesPermissions([PICSTORK_FEATURES.REPORTS])
      .then((permissions) => {
        if (this.props.location.state) {
          let { user, type, task } = this.props.location.state;
          this.setState((state) => ({
            ...state,
            permissions,
            avatar_url: `${BLOB_URL}/${permissions?.container}/profileImage/${(permissions?.container?.split("user-"))[1]}.jpeg`,
            user,
            type,
            task,
          }),
            () => {
              this.getReportHeader()
              this.getSummaryDetails()
              API.getUserSubbedServices().then((response) => {
                let services = response[0];
                if (services.length == 1 && services[0].title == "AeroStream") {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: false,
                  }));
                } else {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: true,
                  }));
                }
              });

            })
        } else {
          API.getUserType().then(
            ([blah, task, user]) => {
              this.setState(
                (state) => ({
                  ...state,
                  user,
                  task,
                }),
                () => {
                }
              );
            },
            (e) => {
              window.location.replace("/login");
            }
          );
        }
      })
      .catch(e => {
        window.location.replace("/")
      })

  }

  downloadPdf = async () => {
    this.setState({ downloadProgress: true })
    API.downloadPdfFile(this.state.task.task_id, 'Defect', this.state.task.image_collection_id, this.state.task.tasktype, this.state.filetype).then(res => {
      if (res == '202') {
        this.setState({
          downloadProgress: false,
          downloadWarning: false,
          firstTimeDownload: true,
        })
      } else {
        this.setState({
          downloadProgress: false,
          downloadWarning: false,
        })
      }
    })
  }

  generatePdf = async () => {
    cookie.set('restrictLogout', true)
    let pdfData = {
      planName: this.state.reportSubTitle,
      imageSetName: this.state.task.collection_name,
      totalImages: this.state.task.images,
      processedImages: this.state.imagesProcessed,
      objects: this.state.totalObjectsDetected,
      pixels: this.state.total_resolution,
      total_size: this.state.total_storage,
      avatar_url: this.state.company_logo,
      username: this.state.company_name,
      task_name: this.state.reportTitle,
      task_id: this.state.task.task_id,
      collection_id: this.state.task.image_collection_id,
      selectedSeverity: this.state.selectedSeverity,
      windMillTask: this.state.windMillTask,
      totalTableData: this.state.totalTableData,
      filetype: this.state.filetype
    }
    let pieData = this.state.pieData.datasets[0].data
    if (this.props.location.state.task?.linked_map) API.generateOrthoDefectPdf(this.state.showData, this.state.task.task_name, pdfData, pieData).then(res => {
      cookie.set('restrictLogout', false)
      if (res.status == 200) {
        this.setState((state) => ({
          showProcessPopup: false,
          downloadWarning: false,
          generation_status: false,
          processMessage: null,
        }))
      }
    })
    else API.generateDefectPdf(this.state.showData, this.state.task.task_name, pdfData, pieData).then(res => {
      cookie.set('restrictLogout', false)
      if (res.status == 200) {
        this.setState((state) => ({
          showProcessPopup: false,
          downloadWarning: false,
          generation_status: false,
          processMessage: null,
        }))
      }

    })
  }

  changeCompanyLogo = (event) => {
    // let companyLogo = event.target.value
    let targetFilesArr = [];
    targetFilesArr = Array.from(event.target.files);
    let companyLogo = targetFilesArr[0]
    this.setState((state) => ({
      ...state,
      company_logo1: companyLogo,
    }), () => {
      this.uploadCompanLogo()
    })
  }
  uploadCompanLogo = () => {
    const data = new FormData()
    data.append("file", this.state.company_logo1)
    API.uploadCompanyLogo(this.state.reportheader.id, data).then((uploadlogo) => {
      if (uploadlogo.status == 200) {
        this.setState((state) => ({
          ...state,
          companyLogo1: undefined,
        }), () => {
          this.getReportHeader()
        })
      } else {
        console.log('error while uploading logo')
      }
    })
  }
  handleChange = (e) => {
    let t = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [t.name]: t.value,
    }))
  }
  togglePanning = () => {
  }

  closeActivityPopup = () => {
    this.getReportHeader()
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      downloadWarning: true,
      generation_status: false,
      processMessage: null,
    }))
  }

  isReportEditable = () => {
    return this.state.permissions[PICSTORK_FEATURES.REPORTS].EDIT && !(this.state.task.sharedBy && this.state.task.sharedBy.isShared)
  }

  isReportUpdate = () => {
    return ((this.state.report_generated == null && this.state.minutesDiff > 30)
      || (this.state.report_generated !== null && this.state.report_generated < this.state.report_modified)
      || (this.state.generation_status && this.state.report_generated == null) || this.state.enable_generate_report
    )
  }

  checkedTrue(index) {
    let temp = this.state.reportData
    temp[index].visibleInReport = !temp[index].visibleInReport
    this.setState((state) => ({
      ...state,
      reportData: temp
    }))
  }

  severityLevelList = (severity, severityData, dataLength, isShowAnnotation) => {
    this.setState({ selectedSeverity: severity, showData: severityData, imagesProcessed: dataLength, showAnnotation: isShowAnnotation }, () => {
      this.loadTiffImageSrc()
      this.createPageDataArray()
    })
  }
  downloadPDFfromURL = async (blobUrl) => {
    return new Promise((resolve, reject) => {

      fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => {
          // Step 2: Create a link element with a download attribute
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'output.pdf';

          // Step 3: Trigger a click event on the link to initiate the download
          link.click();

          // Cleanup
          window.URL.revokeObjectURL(link.href);
          resolve()
        })
        .catch(error => {
          console.error('Error downloading PDF:', error);
          reject()
        });
    })
  }
  render() {
    let {
      user,
      type,
      task,
      totalObjectsDetected,
      sev1total,
      sev2total,
      sev3total,
      sev4total,
      sev5total,
      userperpage,
      currentpage,
      currentPageData
    } = this.state;

    const handleClick = (event) => {
      document.getElementById("hiddenFileInput").click();
    };
    return (
      <>

        <div className="top-bar" style={{ position: 'fixed', width: '100%', zIndex: 5 }}>
          <ActivityPopup
            item={"Report"}
            open={this.state.showProcessPopup}
            icon={this.state.downloadWarning ? this.state.processPopupIcon : "WAIT"}
            action={this.state.downloadWarning ? this.state.processAction : "UPDATE"}
            title={"Report progress"}
            msg={this.state.downloadWarning ? this.state.processMessage : ' '}
            close={() => this.closeActivityPopup()}
            onClickOk={() => {
              this.setState({
                showProcessPopup: false
              }, () => {
                this.downloadPdf()
              })
            }}
          >{!this.state.downloadWarning ? <>
            <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
              Your report is being generated, we will notify you on email once your report is available for download.
            </div>
            <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
              <Button isBtnActive text="Ok" onClick={() => {
                this.closeActivityPopup()
                cookie.set('restrictLogout', false)
              }} />
            </div>
          </> : <></>}
          </ActivityPopup>

          <ActivityPopup
            item={"WARNING"}
            open={this.state.filterWarningPopup}
            icon="WARNING"
            title={"WARNING"}
            msg={"Severity filter will be removed, are you sure you want to edit the report ?"}
            close={() => {
              this.setState({
                filterWarningPopup: false,
              })
            }}
            onClickOk={() => {
              this.setState({
                filterWarningPopup: false,
                isEdit: !this.state.isEdit,
                selectedSeverity: "All",
                showData: this.state.allData,
                imagesProcessed: this.state.allData.length,
                showAnnotation: true
              }, () => {
                this.loadTiffImageSrc1()
                this.createPageDataArray()
              })
            }}
          >
          </ActivityPopup>

          <ActivityPopup
            item={"WARNING"}
            open={this.state.deleteAnotationPopup}
            icon="WARNING"
            title={"WARNING"}
            msg={"Are you sure to delete this Anotation. ?"}
            close={() => {
              this.setState({
                deleteAnotationPopup: false,
                selectedRegionToDelete: undefined,
              })
            }}
            onClickOk={() => {
              this.deleteAnnotation(this.state.selectedRegionToDelete)
            }}
          >
          </ActivityPopup>

          {this.state.firstTimeDownload ?
            <ActivityPopup
              item={"Report"}
              open={this.state.firstTimeDownload}
              icon={"WAIT"}
              action={""}
              title={"Report progress"}
              msg={' '}
              close={() => this.closeActivityPopup()}
            >
              <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
                Your report is being generated, we will notify you on email once your report is available for download.
              </div>
              <div style={{ display: "flex", position: "absolute", bottom: "22px", right: "5%" }}>
                <Button text={"Ok"} isBtnActive onClick={() => {
                  this.setState({ firstTimeDownload: false })
                }} />
              </div>
            </ActivityPopup> : <></>}

          {this.state.downloadProgress ? <ActivityPopup
            item={"Report"}
            open={this.state.downloadProgress}
            icon={"WAIT"}
            action={""}
            title={"Report progress"}
            msg={' '}
            close={() => this.closeActivityPopup()}
          >
            <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
              Getting your file to download, please wait.
              <img src={Loader} style={{ height: "70px", width: "100%", marginLeft: "15px" }} />
            </div>
          </ActivityPopup>
            : <></>}
          <div className="breadCrumbs">
            <BreadCrumbs
              isLoading={this.state.isReportLoading || task.project_name == null}
              data={[
                {
                  displayName: "projects",
                  pathname: "/picstork/dashboard",
                  state: {
                    type,
                    user,
                  }
                },
                {
                  displayName: task.project_name,

                  pathname: "/picstork/project",
                  state: {
                    type,
                    user,
                    project: { "project_name": task.project_name, "id": task.project_id },
                  }
                },
                {
                  displayName: task.task_name
                }
              ]} />
            <ActiveRole />
          </div>
        </div >
        {
          this.state.loading ? <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
          </div> :
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <div style={{ width: '77%', margin: '3% 2% 0% 2%' }}>

                <div style={{ width: "250mm", margin: "auto", display: "flex", justifyContent: "space-between" }} >
                  <div style={{ marginTop: "1.0rem" }}>
                    <div>
                      <div className="pagination-label">
                        {`Page : ${this.state.currentpage} / ${this.state.mainData.length - 1}`}
                      </div>
                    </div>
                  </div>
                  <div style={{ float: "right", marginTop: "1.0rem", display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <div className="pagination-label" >
                        Go to page
                      </div>

                      <div className="add-user-form-text-wrapper">
                        <input type="text" className="pagination-text-box" onChange={(event) => {

                          this.changepage(parseInt(event.target.value))
                          this.setState({ tempPageNo: undefined })
                          // this.getSummaryDetails()
                        }} name="currentpage" value={currentpage} />
                      </div>
                    </div>
                    {this.state.currentpage == 1 ?
                      <button className="pagination-button-inactive" style={{ marginLeft: "15px" }}>
                        <div  >
                          <img src={prevIcon} style={{ height: "17px", height: "17px" }} />
                        </div>
                      </button> :

                      <button className="pagination-button-active" style={{ marginLeft: "15px" }} onClick={this.prevpage}>
                        <div >
                          <img src={prevIcon} style={{ height: "17px", height: "17px" }} />
                        </div>
                      </button>}

                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                      <button className="pagination-button-inactive" >
                        <div >
                          <img src={nextIcon} style={{ height: "17px", height: "17px" }} />
                        </div>
                      </button> :
                      <button className="pagination-button-active" onClick={this.nextpage}>
                        <div >
                          <img src={nextIcon} style={{ height: "17px", height: "17px" }} />
                        </div>
                      </button>
                    }
                  </div>
                </div>
                <div id="defect-report">
                  {!this.state.isReportLoading ?
                    <div
                      id="pageprint"
                      style={{
                        margin: '6px auto',
                        padding: "50px 30px 20px 30px",
                        width: "256mm",
                        height: "86vh",
                        overflowY: "auto",
                        boxShadow: "0px 6px 18px rgba(0, 0, 0, 18%)",
                      }}>
                      {currentpage === 1 ? <>
                        {/* -------------Report header ------------ */}
                        <div>
                          <input
                            name="company_logo"
                            id="hiddenFileInput"
                            type="file"
                            className=""
                            height="100px"
                            required
                            onChange={this.changeCompanyLogo}
                            accept='image/*'
                            // multiple
                            style={{ display: "none" }}
                          />

                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                            <div style={{ width: '60%' }}>
                              {this.state.titleEdit ? <div style={{ height: '47px' }}>
                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Title</label><br /> */}
                                <input maxLength={'30'} type="text" value={this.state.reportTitle} name='reportTitle'
                                  className="add-user-form-text2" style={{ width: '300px', fontSize: '18px' }} onChange={this.handleChange} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer', }} src={saveImage} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      titleEdit: false,
                                      editHeader: false,
                                    }), () => {
                                      this.updateReportHeader()
                                    })
                                }} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      titleEdit: false,
                                      editHeader: false,
                                    }))
                                }} />
                              </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', }}>
                                <div style={{ fontSize: '20px', fontWight: '500', marginBottom: '10px' }} className="long-text-fix">{this.state.reportTitle}</div>
                                {!(this.isReportEditable()) ? <></> :
                                  <div style={{ height: '100%', display: "flex", marginTop: '0px', alignItems: 'center' }}>
                                    {
                                      this.state.isEdit ?
                                        <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', marginBottom: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                          this.setState(
                                            (state) => ({
                                              ...state,
                                              titleEdit: true,
                                              editHeader: true
                                            }))
                                        }} /> : <></>
                                    }


                                  </div>}
                              </div>
                              }

                              {this.state.subTitleEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Sub Title</label><br /> */}
                                <input maxLength={'30'} type="text" value={this.state.reportSubTitle} name='reportSubTitle'
                                  className="add-user-form-text2" style={{ width: '300px', fontSize: '16px' }} onChange={this.handleChange} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      subTitleEdit: false,
                                      editHeader: false,
                                    }), () => {
                                      this.updateReportHeader()
                                    })
                                }} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      subTitleEdit: false,
                                      editHeader: false,
                                    }))
                                }} />
                              </div> : <div style={{ display: 'flex', alignItems: 'center', height: '47px', marginTop: '10px' }}>
                                <div style={{ fontSize: '18px', fontWight: '500' }} className="long-text-fix">{this.state.reportSubTitle}</div>
                                {!(this.isReportEditable()) ? <></> :
                                  this.state.isEdit ?
                                    < img id="reportToolImage" data-html2canvas-ignore className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                      this.setState(
                                        (state) => ({
                                          ...state,
                                          subTitleEdit: true,
                                          editHeader: true,
                                        }))
                                    }} /> : <></>
                                }</div>}
                            </div>

                            <div style={{}}>
                              <div style={{ textAlign: 'end', marginRight: '45px' }}>
                                {!(this.isReportEditable()) ? <></> :
                                  this.state.isEdit ? <img id="reportToolImage" className="reportToolImage" style={{ marginRight: '-125px', height: "15px", width: "15px", cursor: 'pointer', marginTop: '-50px' }} src={edit} onClick={handleClick} /> : <></>}

                                <img style={{ maxHeight: "80px", minHeight: "50px", minWidth: "70px", maxWidth: "100px", borderRadius: '10px', cursor: 'pointer' }} src={`${this.state.company_logo}?${this.state.permissions?.st}`} onError={(e) => { e.target.onerror = null; e.target.src = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG` }} />
                              </div>

                              {this.state.companyNameEdit ? <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>
                                {/* <label style={{ fontSize: '16px', fontWeight: '500' }} >Company Name</label><br /> */}


                                <input maxLength={'20'} style={{ width: '200px', fontSize: '16px' }} type="text" value={this.state.company_name} name='company_name'
                                  className="add-user-form-text2" onChange={this.handleChange} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "25px", width: "22px", cursor: 'pointer' }} src={saveImage} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      companyNameEdit: false,
                                      editHeader: false
                                    }), () => {
                                      this.updateReportHeader()
                                    })
                                }} />
                                <img id="reportToolImage" className="reportToolImage" style={{ marginLeft: '10px', height: "22px", width: "22px", cursor: 'pointer' }} src={close} onClick={() => {
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      companyNameEdit: false,
                                      editHeader: false,
                                    }))
                                }} />
                              </div> :
                                <div style={{ display: 'flex', alignItems: 'center', height: '47px' }}>

                                  <div style={{ fontSize: '20px' }} className="long-text-fix">{this.state.company_name}</div>
                                  {!(this.isReportEditable()) ? <></> :
                                    this.state.isEdit ?
                                      < img id="reportToolImage" data-html2canvas-ignore className="reportToolImage" style={{ marginLeft: '10px', height: "15px", width: "15px", cursor: 'pointer' }} src={edit} onClick={() => {
                                        this.setState(
                                          (state) => ({
                                            ...state,
                                            companyNameEdit: true,
                                            editHeader: true,
                                          }))
                                      }} /> : <></>

                                  }
                                </div>
                              }
                            </div>
                          </div>
                          <hr style={{ width: '100%', margin: '10px 0px 20px 0px', height: '1px', padding: '0px' }} />
                        </div>
                        {/* ---------------------------------------*/}
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '50px' }}>
                          <p>Pixels Processed: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_resolution} MP</span></p>
                          <p>Total Storage: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.total_storage > 1024 ? `${Number(this.state.total_storage) / 1024}` : `${this.state.total_storage}`}
                            {this.state.total_storage > 1024 ? ' GB' : ' MB'}  </span></p>
                        </div>

                        {/* Analysis Report*/}
                        <p style={{ marginBottom: '20' }}><div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                          Analysis Summary
                        </div>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
                            <table style={{ borderRadius: '4px', width: '100%', boxShadow: '0px 6px 18px rgba(0,0,0,0.06)' }}>
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "50%" }} />
                              <tbody>
                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Imageset</td>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{getLimitedName(this.state.task.collection_name.replace(AppConstants.IMAGE_SET_RENAME_VARIABLE, ""), 30)}</td>
                                </tr>
                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Total images</td>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{this.state.task.images}</td>
                                </tr>
                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Processed images</td>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{this.state.imagesProcessed}</td>
                                </tr>
                                <tr style={{ border: 'solid 1px #EBEFF2', padding: '5px', borderRadius: '4px', height: '42px ', fontSize: '13px' }}>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>Total defects/annotations </td>
                                  <td style={{ border: 'solid 1px #EBEFF2', padding: '0px 10px' }}>{totalObjectsDetected}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div style={{ marginTop: "50px" }} >
                            <div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                              Severity Distribution
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ margin: "50px 0px", width: '70%', height: '100%' }}>
                                {this.state.pieData ?
                                  <Pie
                                    height={'130'}
                                    options={{
                                      maintainAspectRatio: false,
                                      plugins: {
                                        legend: {
                                          display: false
                                        }
                                      }
                                    }}
                                    data={this.state.pieData}
                                  />
                                  : <></>}
                              </div>
                              <div className="summary" style={{ width: '30%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div className="analysisTable1 itemValue" style={{ verticalAlign: 'middle' }}>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[0] }} />
                                    <div>{`${AppConstants.SEVIRITY._1}: ${sev1total}`}</div>
                                  </div>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[1] }} />
                                    <div>{`${AppConstants.SEVIRITY._2}: ${sev2total}`}</div>
                                  </div>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[2] }} />
                                    <div>{`${AppConstants.SEVIRITY._3}: ${sev3total}`}</div>
                                  </div>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[3] }} />
                                    <div>{`${AppConstants.SEVIRITY._4}: ${sev4total}`}</div>
                                  </div>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[4] }} />
                                    <div>{`${AppConstants.SEVIRITY._5}: ${sev5total}`}</div>
                                  </div>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'left', height: '25px', alignItems: 'center' }}>
                                    <div className="graph-legend-shape" style={{ height: '12px', width: '35px', backgroundColor: graphColours[5] }} />
                                    <div>{`${AppConstants.SEVIRITY.NONE}: ${totalObjectsDetected - (sev1total + sev2total + sev3total + sev4total + sev5total)}`}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="page-break"></div>
                        </p>
                        {/* ------------------ */}
                      </>
                        : this.state.windMillTask && currentPageData.showImage == false && currentPageData.tableRecordShow ? <div>
                          <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px" }} className="customers-table">
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '10%' }} />

                            <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                              <th style={{ paddingLeft: "20px" }}>Blade</th>
                              <th style={{ paddingLeft: "20px" }}>Side</th>
                              <th style={{ paddingLeft: "20px" }}>Issues</th>
                              <th style={{ textAlign: 'center' }}>Severity</th>
                              <th style={{ textAlign: 'center' }}>Size</th>
                              <th style={{ textAlign: 'center' }}>MR</th>
                            </tr>

                            {currentPageData.tableRecords.map((record) =>
                              <tr className="customers-table-data-row">
                                <td>{record.blade.length > 10 ? record.blade.substring(0, 10) : record.blade}</td>
                                <td>{record.side.length > 10 ? record.side.substring(0, 10) : record.side}</td>
                                <td>{record.issues.length > 10 ? record.issues.substring(0, 10) : record.issues}</td>
                                <td style={{ textAlign: 'center' }}>{record.severity}</td>
                                <td style={{ textAlign: 'center' }}>{record.size}</td>
                                <td style={{ textAlign: 'center' }}>{record.alt}</td>
                              </tr>
                            )}

                          </table>
                        </div>
                          : <>
                            {currentPageData.showImage ?
                              <>
                                <div className="header" style={{ fontWeight: 600, fontSize: '18px' }}>
                                  {
                                    this.state.selectedSeverity === 'All' && this.state.currentpage > 2 ? 'Detailed Analysis' : this.state.currentpage > 2 ? `Severity-${this.state.selectedSeverity} Analysis` : ''
                                  }
                                </div>
                                <p style={{}}>

                                  {/* <p id={a} style={{}}> */}
                                  <div>
                                    <table style={{ border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px" }} className="customers-table">
                                      <col style={{ width: '24%' }} />
                                      <col style={{ width: '20%' }} />
                                      <col style={{ width: '16%' }} />
                                      <col style={{ width: '16%' }} />
                                      <col style={{ width: '24%' }} />

                                      <tr style={{ height: "40px", backgroundColor: "#EAEAEA", fontSize: '13px' }}>
                                        <th style={{ paddingLeft: "20px" }}>Image Name</th>
                                        <th style={{ textAlign: 'center' }}>Location(lat,lng)</th>
                                        <th style={{ textAlign: 'center' }}>Altitude(m)</th>
                                        <th style={{ textAlign: 'center' }}>Captured on</th>
                                        <th style={{ textAlign: 'center' }}>Tags</th>
                                      </tr>
                                      <tr className="customers-table-data-row">
                                        <td style={{}}>{currentPageData.image.length > 18 ? currentPageData.image.substring(0, 18) : currentPageData.image}</td>
                                        <td style={{ textAlign: 'center' }}>{currentPageData.imgData.latitude ? Number(currentPageData.imgData.latitude).toFixed(4) : '-'} {currentPageData.imgData.longitude ? `, ${Number(currentPageData.imgData.longitude).toFixed(4)}` : ''}</td>
                                        <td style={{ textAlign: 'center' }}>{currentPageData.imgData.altitude && currentPageData.imgData.altitude.toString().toLowerCase() != 'nan' ? Number(currentPageData.imgData.altitude).toFixed(4) : '-'}</td>
                                        <td style={{ textAlign: 'center' }}>{dateFormat(currentPageData.imgData.created_on, 'dd mmm yyyy')}</td>
                                        <td style={{ textAlign: 'center' }}>{currentPageData.tags ? currentPageData.tags : '-'}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div style={{ height: '604px', width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: "20px", }}>
                                    <div style={{ height: '604px', width: this.state.windMillTask ? '80%' : '100%' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        {this.state.showAnnotation ? <div style={{ height: "604px", width: "100%", pointerEvents: "" }}>
                                          {this.props.location.state.task?.linked_map ?
                                            <MapDetections
                                              collectionId={this.state.task?.image_collection_id}
                                              regions={currentPageData.regions}
                                              imageName={currentPageData.image}
                                              permissions={this.state.permissions}
                                              onLoaded={(e) => {
                                                this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                              }}
                                            />
                                            :
                                            <>{currentPageData.loading ? <img src={Loader} style={{ zIndex: 5, width: "10vw", marginLeft: "8%", marginTop: "10%" }} /> : <></>}
                                              <Annotation
                                                defectReport={true}
                                                disableMaxHeight={true}
                                                panning={false}
                                                fillRegions={false}
                                                allowZoom={false}
                                                allowPanning={false}
                                                togglePanning={this.togglePanning}
                                                searchable={false}
                                                creatable={false}
                                                disabledUpdate={true}
                                                showTags={this.state.showTags}
                                                regionAdded={this.onRegionAdded}
                                                onClassAdded={(id, object) => { }}
                                                onClassUpdate={() => { }}
                                                classList={this.state.task.type ? this.state.task.type : []}
                                                regions={
                                                  currentPageData.regions
                                                }
                                                imageSrc={currentPageData.imageSrc}
                                                onImageOrVideoLoaded={(e) => {
                                                  this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                                }}
                                                currTempCalculated={true}
                                              />
                                            </>
                                          }
                                        </div> : <></>}
                                      </div>

                                    </div>
                                    {this.state.windMillTask && currentPageData.imgData?.windmillData ?
                                      <> {currentPageData.imgData?.windmillData && currentPageData.imgData.windmillData?.annotations ?
                                        <div style={{ height: '604px', width: '19%', pointerEvents: "none" }}>
                                          <Annotation
                                            defectReport={true}
                                            disableMaxHeight={true}
                                            panning={false}
                                            fillRegions={false}
                                            allowZoom={false}
                                            allowPanning={false}
                                            togglePanning={this.togglePanning}
                                            searchable={false}
                                            creatable={false}
                                            disabledUpdate={true}
                                            showTags={this.state.showTags}
                                            regionAdded={this.onRegionAdded}
                                            onClassAdded={(id, object) => { }}
                                            onClassUpdate={() => { }}
                                            classList={this.state.task.type ? this.state.task.type : []}
                                            regions={
                                              currentPageData.imgData.windmillData.annotations.map(a => {
                                                return ({
                                                  y: Number(a[0]),
                                                  x: Number(a[1]),
                                                  w: Number(a[3]) - Number(a[1]),
                                                  h: Number(a[2]) - Number(a[0]),
                                                  className: 'abc',
                                                  type: 'box',
                                                  index: 1,
                                                  color: "#ff0000",
                                                })
                                              })
                                            }
                                            imageSrc={currentPageData.imgData.windmillData.side == 'PS' ? PS : currentPageData.imgData.windmillData.side == 'LE' ? LE : currentPageData.imgData.windmillData.side == 'TE' ? TE : SS}
                                            onImageOrVideoLoaded={(e) => {
                                              this.setState({ currentPageData: { ...currentPageData, loading: false } })
                                            }}
                                            currTempCalculated={true}
                                          />
                                        </div> : <div style={{ height: '604px', width: '19%', }}>
                                          <img style={{ height: '604px' }} src={currentPageData.imgData.windmillData.side == 'PS' ? PS : currentPageData.imgData.windmillData.side == 'LE' ? LE : currentPageData.imgData.windmillData.side == 'TE' ? TE : SS} />
                                        </div>
                                      }
                                      </> : <></>}
                                  </div>


                                  {this.state.showAnnotation ? <>
                                    <div className="header" style={{ fontWeight: 500, fontSize: '16px', marginTop: '20px' }}>Defects </div>
                                    <div style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 430px))', rowGap: '100px', width: '900px', marginLeft: '35px' }} className="aerocapture-cards-tray-images-map1">
                                      {
                                        currentPageData.cropRegions.map((region, i) => {
                                          return <div>
                                            {
                                              i > 5 && (i % 6 == 0 || i % 6 == 1) ?
                                                <div className="page-break"></div>
                                                : <></>
                                            }
                                            <div style={{ marginTop: i > 5 && (i % 6 == 0 || i % 6 == 1) ? '20px' : '', height: '450px', width: '350px' }}>
                                              <div style={{ position: "relative", height: '350px', width: '350px', border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px", overflow: "hidden" }}>

                                                {/* delete button */}
                                                {this.state.isEdit ?
                                                  <div style={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}
                                                    onClick={() => {
                                                      this.setState({
                                                        deleteAnotationPopup: true,
                                                        selectedRegionToDelete: region,
                                                      })
                                                    }}
                                                  >
                                                    <div style={{
                                                      height: "25px", width: "25px", borderRadius: "20px", display: "flex", justifyContent: "center",
                                                      backgroundColor: "#00000099",
                                                    }}>
                                                      < img style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        height: "11px",
                                                        width: "11px",
                                                        marginTop: '25%',
                                                        verticalAlign: "middle",
                                                        borderStyle: "none",
                                                      }}
                                                        src={WhiteCloseIcon}
                                                      />
                                                    </div>
                                                  </div> : <></>}

                                                {this.props.location.state.task.linked_map ?
                                                  <CropOrthoImage
                                                    collectionId={this.state.task?.image_collection_id}
                                                    polygon={region.innerRegion.poly}
                                                    imageName={currentPageData.image}
                                                    permissions={this.state.permissions}
                                                  />
                                                  : <>
                                                    {this.state.refreshCanvas ? <CropAnnotation imgSrc={currentPageData.imageSrc} imageName={currentPageData.image.split(".")[0]} region={region} imgData={currentPageData.imgData} /> : <></>}
                                                  </>}

                                              </div>

                                              <div style={{ position: "relative", background: 'white', color: '#3c3c3c', border: '1px solid #C3C3C3', borderTop: 0 }} className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                  <div style={{ wordWrap: "break-word", fontSize: '14px', fontWeight: 500, width: "100%", display: "flex" }}>
                                                    {/* if region name is not present show index */}
                                                    {this.props.location.state.task.linked_map
                                                      ? region.innerRegion?.name
                                                        ? <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}> <div className="long-text-fix">{`${getRegionNameBeforeUnderscore(region.innerRegion.name)}`}</div><div>{`_${getRegionNameAfterUnderscore(region.innerRegion.name)}`}</div></div>
                                                        : <div>{`${region.index}.`}</div>
                                                      : <div>{`${region.index}.`}</div>}
                                                    {region.innerRegion.name ? <></> :
                                                      this.state.isEdit ? this.state.showData ?
                                                        <select style={{ border: "none", background: "transparent", marginLeft: "10px", outline: "none", overflowX: "hidden", width: "100%" }}
                                                          onChange={(e) => {
                                                            this.updateClasses(e.target.value, region.innerRegion.className, region,)
                                                          }}>
                                                          {
                                                            this.state?.classes?.map(cls => {
                                                              return <option value={cls} selected={region.innerRegion.className == cls} style={{ width: "100%", wordWrap: "break-word" }}>{cls}</option>
                                                            })
                                                          }
                                                        </select>
                                                        : <></> : <div style={{ width: "100%" }}>{region.innerRegion.className.length > 31 ? getLimitedName(region.innerRegion.className, 31) : region.innerRegion.className}</div>
                                                    }
                                                  </div>
                                                </div>

                                                <div style={{ marginTop: "10px" }}>
                                                  <div style={{ overflow: "hidden", display: "flex", alignItems: "center", fontSize: '12px', zIndex: "5" }}>
                                                    <span style={{ fontWeight: 500 }}>Severity level </span>

                                                    <div style={{ marginLeft: "10px" }}>
                                                      {this.state.isEdit ?
                                                        <select style={{ border: "none", background: "transparent", marginLeft: "10px", outline: "none" }} onChange={(e) => {
                                                          this.updateRemark(e.target.value, region, 'severity')
                                                        }}>
                                                          <option value="None" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "None" ? true : false : false}>None</option>
                                                          <option value="1" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "1" ? true : false : false}>Normal</option>
                                                          <option value="2" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "2" ? true : false : false}>Medium</option>
                                                          <option value="3" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "3" ? true : false : false}>High</option>
                                                          <option value="4" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "4" ? true : false : false}>Higher</option>
                                                          <option value="5" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "5" ? true : false : false}>Very High</option>
                                                        </select> : <span>{region.innerRegion.data && region.innerRegion.data.severity ? getSevirityNumber(region.innerRegion.data.severity) : "None"}</span>}
                                                    </div>
                                                  </div>

                                                  <div style={{ overflow: 'hidden', height: "", marginBottom: "10px", display: "flex", alignItems: "start" }}>
                                                    <span style={{ fontWeight: 500, marginTop: "8px" }}> Remark  </span>
                                                    {this.state.isEdit ? <InputWithSaveAndEdit
                                                      value={region.innerRegion.data && region.innerRegion.data.note ? getLimitedName(region.innerRegion.data.note, 100) : ""}
                                                      onSave={(newRemark) => {
                                                        this.setState(
                                                          (state) => ({
                                                            ...state,
                                                            addRemark: false,
                                                            editHeader: false,
                                                          }), () => {
                                                            this.updateRemark(newRemark, region, 'note')
                                                          })
                                                      }}
                                                      isEditable={this.isReportEditable()}
                                                      onChange={
                                                        (e) => {
                                                          this.handleRemark(e)
                                                        }
                                                      } /> : <span style={{
                                                        marginTop: "8px", wordWrap: "break-word",
                                                        width: "230px", marginLeft: "10px",
                                                        fontSize: "12px",
                                                      }}>{region.innerRegion.data && region.innerRegion.data.note ? getLimitedName(region.innerRegion.data.note, 100) : "Not available"}</span>}
                                                    {/* </div> */}
                                                  </div>
                                                  {this.props.location.state.task.linked_map && <div>
                                                    <span style={{ fontWeight: 500, marginTop: "8px" }}>Location</span>
                                                    <span style={{
                                                      marginTop: "8px", wordWrap: "break-word",
                                                      width: "230px", marginLeft: "10px",
                                                      fontSize: "12px",
                                                    }}>{region?.innerRegion?.point ? region?.innerRegion?.point.map(p => p.toFixed(8))?.join(" , ") : ""}</span>
                                                  </div>}
                                                </div>

                                              </div>
                                            </div>

                                          </div>
                                        })
                                      }
                                    </div>
                                  </> : <></>}
                                </p>
                              </> :
                              <>
                                {currentPageData && this.state.showAnnotation ? <>
                                  <div className="header" style={{ fontWeight: 500, fontSize: '16px', marginTop: '20px' }}>Defects </div>
                                  <div style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 430px))', rowGap: '100px', width: '900px', marginLeft: '35px' }} className="aerocapture-cards-tray-images-map1">
                                    {
                                      currentPageData.cropRegions.map((region, i) => {
                                        return <div>
                                          <div style={{ marginTop: i > 5 && (i % 6 == 0 || i % 6 == 1) ? '20px' : '', height: '450px', width: '350px' }}>
                                            <div style={{ position: "relative", height: '350px', width: '350px', border: '1px solid #C3C3C3', borderRadius: "5px 5px 0px 0px", overflow: "hidden" }}>
                                              {this.state.isEdit ?
                                                <div style={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}
                                                  onClick={() => {
                                                    this.setState({
                                                      deleteAnotationPopup: true,
                                                      selectedRegionToDelete: region,
                                                    })
                                                  }}
                                                >
                                                  <div style={{
                                                    height: "25px", width: "25px", borderRadius: "20px", display: "flex", justifyContent: "center",
                                                    backgroundColor: "#00000099",
                                                  }}>
                                                    < img style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      height: "11px",
                                                      width: "11px",
                                                      marginTop: '25%',
                                                      verticalAlign: "middle",
                                                      borderStyle: "none",
                                                    }}
                                                      src={WhiteCloseIcon}
                                                    />
                                                  </div>
                                                </div> : <></>}
                                              {this.props.location.state.task.linked_map ?
                                                <CropOrthoImage
                                                  collectionId={this.state.task?.image_collection_id}
                                                  polygon={region.innerRegion.poly}
                                                  imageName={currentPageData.image}
                                                  permissions={this.state.permissions}
                                                />
                                                : <>
                                                  {this.state.refreshCanvas ? <CropAnnotation imgSrc={currentPageData.imageSrc} imageName={currentPageData.image.split(".")[0]} region={region} imgData={currentPageData.imgData} /> : <></>}
                                                </>}
                                            </div>
                                            <div style={{ position: "relative", background: 'white', color: '#3c3c3c', border: '1px solid #C3C3C3', borderTop: 0 }} className="card-body change-color customer-cards-dashboard-text project-card-body">
                                              <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                                <div style={{ wordWrap: "break-word", fontSize: '14px', fontWeight: 500, width: "100%", display: "flex" }}>
                                                  {this.props.location.state.task.linked_map
                                                    ? region.innerRegion?.name !== undefined
                                                      ? <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}> <div className="long-text-fix">{`${getRegionNameBeforeUnderscore(region.innerRegion.name)}`}</div><div>{`_${getRegionNameAfterUnderscore(region.innerRegion.name)}`}</div></div>
                                                      : <div>{`${region.index}.`}</div>
                                                    : <div>{`${region.index}.`}</div>}
                                                  {region.innerRegion.name ? <></> :
                                                    this.state.isEdit ?
                                                      this.state.showData ?
                                                        <select style={{ border: "none", background: "transparent", marginLeft: "10px", outline: "none", width: "100%", overflowX: "hidden" }}
                                                          onChange={(e) => {
                                                            this.updateClasses(e.target.value, region.innerRegion.className, region,)
                                                          }}>
                                                          {
                                                            this.state?.classes.map(cls => {
                                                              return <option value={cls} selected={region.innerRegion.className == cls} style={{ width: "100%", wordWrap: "break-word" }}>{cls}</option>
                                                            })
                                                          }
                                                        </select> : <></> : <div style={{ width: "100%" }}>{region.innerRegion.className.length > 31 ? getLimitedName(region.innerRegion.className, 31) : region.innerRegion.className}</div>
                                                  }
                                                </div>
                                              </div>

                                              <div style={{ marginTop: "10px" }}>
                                                <div style={{ overflow: "hidden", display: "flex", fontSize: '12px' }}>
                                                  <span style={{ fontWeight: 500 }}>Severity level  </span>
                                                  <div style={{ marginLeft: "10px" }}>
                                                    {this.state.isEdit ?
                                                      <select style={{ border: "none", background: "transparent", marginLeft: "10px", outline: "none" }} onChange={(e) => {
                                                        this.updateRemark(e.target.value, region, 'severity')
                                                      }}>
                                                        <option value="None" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "None" ? true : false : false}>None</option>
                                                        <option value="1" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "1" ? true : false : false}>Normal</option>
                                                        <option value="2" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "2" ? true : false : false}>Medium</option>
                                                        <option value="3" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "3" ? true : false : false}>High</option>
                                                        <option value="4" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "4" ? true : false : false}>Higher</option>
                                                        <option value="5" selected={region.innerRegion.data && region.innerRegion.data.severity ? region.innerRegion.data.severity == "5" ? true : false : false}>Very High</option>
                                                      </select> : <span>{region.innerRegion.data && region.innerRegion.data.severity ? getSevirityNumber(region.innerRegion.data.severity) : "None"}</span>}
                                                  </div>
                                                </div>
                                                <div style={{ overflow: 'hidden', height: "", marginBottom: "10px", display: "flex", alignItems: "start" }}>
                                                  <span style={{ fontWeight: 500, marginTop: "8px" }}>Remark</span>
                                                  {
                                                    this.state.isEdit ? <InputWithSaveAndEdit
                                                      value={region.innerRegion.data && region.innerRegion.data.note ? getLimitedName(region.innerRegion.data.note, 100) : ""}
                                                      onSave={(newRemark) => {
                                                        this.setState(
                                                          (state) => ({
                                                            ...state,
                                                            addRemark: false,
                                                            editHeader: false,
                                                          }), () => {
                                                            this.updateRemark(newRemark, region, 'note')
                                                          })
                                                      }}
                                                      isEditable={this.isReportEditable()}
                                                      onChange={
                                                        (e) => {
                                                          this.handleRemark(e)
                                                        }
                                                      } /> : <span style={{
                                                        marginTop: "8px", wordWrap: "break-word",
                                                        width: "230px", marginLeft: "10px",
                                                        fontSize: "12px",
                                                      }}>{region.innerRegion.data && region.innerRegion.data.note ? getLimitedName(region.innerRegion.data.note, 100) : "Not available"}</span>}
                                                  {/* </div> */}
                                                </div>
                                                {this.props.location.state.task.linked_map && <div>
                                                  <span style={{ fontWeight: 500, marginTop: "8px" }}>Location</span>
                                                  <span style={{
                                                    marginTop: "8px", wordWrap: "break-word",
                                                    width: "230px", marginLeft: "10px",
                                                    fontSize: "12px",
                                                  }}>{region?.innerRegion?.point ? region?.innerRegion?.point.map(p => p.toFixed(8))?.join(" , ") : ""}</span>
                                                  </div>}
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      })
                                    }
                                  </div>
                                </> : <></>}
                              </>
                            }
                          </>}

                      {/* ------------ FOOTER --------- */}
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '120px' }}>
                        <img
                          style={{ height: "45px", borderRadius: '1px', cursor: 'pointer' }}
                          src={`${this.state.company_logo}?${this.state.permissions?.st}`}
                          onError={(e) => { e.target.onerror = null; e.target.src = `/images/${this.state.user.first_name[0].toLowerCase()}.PNG` }}
                        />
                      </div>

                    </div> : <div style={{ height: "100vh", width: "100%", display: "flex", textAlign: "center" }}>
                      <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                    </div>
                  }
                </div>
              </div >
              < div style={{ width: '23%', }
              }>
                <div style={{ height: '15vh', overflow: "auto", position: "fixed", top: "43px", fontSize: "14px", width: "23%", display: "flex", justifyContent: "right", paddingRight: "50px" }}>
                  {
                    <>
                      <div style={{ display: "flex", position: 'absolute', top: '15px', flexDirection: "column", alignItems: "end" }}>
                        <div style={{ display: "flex", alignItems: "right" }}>
                          {this.props.location.state.task.linked_map ? <ButtonWithIcon
                            isDisabled={(this.props.location.state.task.sharedBy?.access_level ? true :
                              !this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD)}
                            text={"Download"} isBtnActive={this.state.isEdit} width="100px"
                            onClick={() => {
                              if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                                this.setState((state) => ({
                                  ...state,
                                  itemName: "Report",
                                  processPopupIcon: "WARNING",
                                  processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                                  showProcessPopup: this.state.downloadWarning ? true : false,
                                }), async () => {
                                  if (!(await checkURLExist(`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${this.state.task.image_collection_id}/reports/Defect-${this.state.task.task_id}.pdf?${this.state.permissions.st}`))) {
                                    this.setState((state) => ({
                                      ...state,
                                      showProcessPopup: true,
                                      filetype: 'pdf'
                                    }), () => {
                                      this.generatePdf()
                                    })
                                  } else if (!this.state.downloadWarning) {
                                    this.setState({
                                      filetype: 'pdf'
                                    }, () => {
                                      this.downloadPdf()
                                    })
                                  }
                                })
                              }
                            }
                            }
                          /> :
                            <><ButtonWithIcon
                              isDisabled={(this.props.location.state.task.sharedBy?.access_level ? true :
                                !this.state.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT)}
                              text={this.state.isEdit ? "Save" : "Edit"} isBtnActive={this.state.isEdit} width="100px"
                              onClick={() => {
                                if (this.state.isEdit && this.state.permissions?.[PICSTORK_FEATURES.REPORTS].EDIT) {
                                  this.getSummaryDetails("All")
                                }
                                this.setState({ isEdit: false, filterWarningPopup: !this.state.isEdit ? true : false, tempPageNo: this.state.currentpage, })
                              }
                              }
                            />

                              {/* Download DropDown */}
                              <DropDownWithButton
                                selectedItem="Download" isButton width="100px"
                                isDisabled={this.state.isEdit || (!this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD)}
                                list={[
                                  {
                                    name: "PDF", onClick: () => {
                                      if (this.state.permissions?.[PICSTORK_FEATURES.REPORTS].DOWNLOAD) {
                                        this.setState((state) => ({
                                          ...state,
                                          itemName: "Report",
                                          processPopupIcon: "WARNING",
                                          processMessage: "Your report generation request is still in progress, This will download your previously saved report. Are you sure you want to continue ? ",
                                          showProcessPopup: this.state.downloadWarning ? true : false,
                                        }), async () => {
                                          if (!(await checkURLExist(`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${this.state.task.image_collection_id}/reports/Defect-${this.state.task.task_id}.pdf?${this.state.permissions.st}`))) {
                                            this.setState((state) => ({
                                              ...state,
                                              showProcessPopup: true,
                                              filetype: 'pdf'
                                            }), () => {
                                              this.generatePdf()
                                            })
                                          } else {
                                            this.setState({
                                              filetype: 'pdf',
                                              downloadProgress: true
                                            }, async () => {
                                              try {
                                                await this.downloadPDFfromURL(`${BLOB_URL}/${this.state.permissions?.container}/FlightImages/collection-${this.state.task.image_collection_id}/reports/Defect-${this.state.task.task_id}.pdf?${this.state.permissions.st}`)
                                                this.setState({
                                                  downloadProgress: false
                                                })
                                              } catch (err) {
                                                this.setState({
                                                  downloadProgress: false
                                                })
                                                Popup.alert("Error", "Something went wrong while downloading pdf")
                                              }
                                            }
                                            )
                                          }
                                        })
                                      }
                                    }
                                  },
                                ]}
                              /></>
                          }
                        </div>
                        {!(this.isReportEditable()) ? <></> :
                          this.isReportUpdate() ?
                            <div style={{ position: '', top: '90px', right: '20px', color: "#3c3c3c", fontSize: '10px', fontWeight: '300', textAlign: "right", marginTop: "5px", marginRight: "5px" }}>
                              Your report data was modified,
                              <div clasName="reportLabel" style={{ color: "#3c3c3c" }}>click here to <span style={{ cursor: "pointer", color: "#2989cf" }} onClick={() => {
                                if (this.state.showData?.length > 0) {

                                  this.setState((state) => ({
                                    ...state,
                                    itemName: "Report",
                                    processPopupIcon: "COMPLETE",
                                    processMessage: "Your report is being generated, we will notify you on email once your report is available for download.",
                                    showProcessPopup: true,
                                    filetype: 'pdf',
                                    updateReportAfterSave: false,
                                    report_generated: false,
                                    enable_generate_report: false
                                  }), () => {

                                    this.generatePdf()
                                    if (!this.props.location.state.task?.linked_map) { //if ortho defect report dont genrate excel
                                      this.setState((state) => ({
                                        ...state,
                                        filetype: 'xslx',
                                        updateReportAfterSave: false,
                                        generation_status: false
                                      }), () => this.generatePdf()
                                      )
                                    }
                                  }
                                  )
                                } else {
                                  this.setState({
                                    report_generated: false,
                                    generation_status: false,
                                    enable_generate_report: false
                                  })
                                  Popup.alert("ERROR", "We are unable to generate the PDF as there is no data available for the selected filter.",)
                                }
                              }}>Update report</span></div>
                            </div>
                            : <></>
                        }

                      </div>
                    </>}
                </div>

                <div style={{ height: '78vh', overflow: 'auto', position: 'fixed', top: '135px', fontSize: "14px", width: '23%', display: "flex", justifyContent: "right", paddingRight: "50px" }}>
                  <div style={{ marginTop: "20px", marginRight: "5px" }}>
                    <div style={{ margin: "5px 0px" }}>Select Severity level</div>

                    <DropDownWithButton
                      selectedItem={this.state.selectedSeverity}
                      isBorder
                      width="140px" textFontSize="13px" textFontWeight="400"
                      isDisabled={this.state.isEdit}
                      list={[
                        {
                          name: AppConstants.SEVIRITY._1, onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("1")
                          }
                        },
                        {
                          name: AppConstants.SEVIRITY._2, onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("2")
                          }
                        },
                        {
                          name: AppConstants.SEVIRITY._3, onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("3")
                          }
                        },
                        {
                          name: AppConstants.SEVIRITY._4, onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("4")
                          }
                        },
                        {
                          name: AppConstants.SEVIRITY._5, onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("5")
                          }
                        },
                        {
                          name: "None", onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("None")
                          }
                        },
                        {
                          name: "All", onClick: () => {
                            this.setState({ enable_generate_report: true })
                            this.getSummaryDetails("All")
                          }
                        },
                      ]}
                    />

                  </div>
                </div>
              </div >
            </div >
        }

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    SubscriptionData: state.subscription.subscriptionData,
  };
};

export default connect(mapStateToProps)(PicStorkDefectReport);
import React, { Component } from 'react';
import circleLeft from '../../icons/prev.png';
import circleRight from '../../icons/next.png';
import dashboardIcon from '../../icons/AeroCaptureInactive.png';

import { connect } from "react-redux";
import * as API from '../../api.js';
import trashIcon from '../assets/icons/trash.svg';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import closeButton from '../../close-button.png';
import detailsIcon from '../../assets/Icons/setDetails.png'
import classesIcon from '../../assets/Icons/setClasses.png'
import summaryIcon from '../../assets/Icons/setSummary.png'
import Popup from "reactjs-popup";
import Loader from '../../icons/loader.svg';
import { Link, Redirect } from 'react-router-dom';
import service from "../../sidebaricon/Services.png";
import thumb from "../../assets/map_placeholder.png";
import { addUserAnalytic, updateOutTime, } from '../../ReusableComponents/reusableFunctions';
import LocationIcon from '../../res/locationwhite.png';
import ViewImagesOnMap from '../../ReusableComponents/inspectOnMap';
import ViewImageInList from '../../ReusableComponents/viewImageInList';
import menuIcon from "../../assets/Icons/menu.png";
import Dropdown from 'react-bootstrap/Dropdown';
import Noimageset from '../../assets/Images/Noimageset.png';
import MultiFileUploader from "./aeroCaptureMultiFileUploader";
import AeroMeghLogo from "../../aeromeghLog.png";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Tiff from 'tiff.js'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Search from "../../Icon/Search.png";
import listViewIcon from '../../icons/listview.png'
import listViewActiveIcon from '../../icons/listViewActiveIcon.png'
import gridViewIcon from '../../icons/gridview.png'
import gridViewActiveIcon from '../../icons/gridViewActiveIcon.png'
import mapViewIcon from '../../assets/sideBarIcons/PlanInactive.png'
import mapViewActive from '../../assets/sideBarIcons/mapView.png'
import imageActive from "../../assets/sideBarIcons/Images.png"
import Help from "../../sidebaricon/Help.png";
import { ActiveRole, getFeaturesPermissions, PICSTORK_FEATURES } from '../../Teams';
import ImagesTypeSelection from './ReusableComp/ImagesTypeSelection';
import AppConstants, { BLOB_URL } from '../../AppConstants';
import { getStorageUserByService } from '../../subscription/UserSubscription';
import { Popup as CustomPopup } from '../../ReusableComponents/reusableFunctions';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={menuIcon} alt="I am alt" />
    </a>
));

class AeroCaptureImageCollectionImages extends Component {

    state = {
        user: {},
        type: "",
        progress: 0,
        showCheckboxes: false,
        selectedImageCount: 0,
        Idx: 0,
        userperpage: 1000000,
        currentpage: 1,
        outId: "",
        userdisplay: [],
        showDownloadPopup: false,
        loader: true,
        services: [],
        counts: 0,
        projects: [],
        collection_name: '',
        task: [],
        images: [],
        typeimg: '',
        reports: [
        ],
        selecttedImages: [],
        showBackIcon: true,
        cancel: false,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        imageset: '',
        scale: 0,
        mapView: false,
        listview: false,
        gridView: true,
        imageLoad: false,
        steps: [{
            title: 'Details',
            icon: detailsIcon,
        }, {
            title: 'Accuracy',
            icon: classesIcon

        }, {
            title: 'Summary',
            icon: summaryIcon

        }],
        currentStep: 0,
        createTaskMsg: "",
        collection_name: "",
        imageCount: 0,
        taskType: null,
        showAddTaskPopup: false,
        addDetectionTaskPopup: false,
        addTrainingTaskPopup: false,
        models: [],
        videoTask: false,
        video_id: "",
        fps: 1,
        selectedModels: [],
        media_name: "",
        media_id: null,
        scrollHeight: "180px",
        collections: [],
        videos: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        thumbURLs: [],
        imageURL: "",
        uploadPercent: 0,
        hasMoreItems: true,
        key: "",
        sortType: 'Last Modified',
        selectTask: false,
        annotation_set_name: "",
        annotationSets: [],
        accuracy: "",
        classes: [],
        taskStatus: "No Task",
        refresh: false,
        // isTaskPresent: false,
        task_name: '',
        addAnnotationTaskPopup: false,
        redirectToTaskPage: false,
        collectionId: '',
        classArray: [],
        classError: '',
        class: '',
        messageColor: false,
        taskPurpose: "Counting",
        filteredModels: [],
        unfilteredModels: [],
    }

    // function to load tiff thumbnails
    loadThumbTiff = async (images) => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < images.length; i++) {
                if (images[i].file_name) {
                    let name = images[i].file_name
                    let turl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/thumb-${images[i].file_name}?${this.state.permissions.st}`
                    images[i].thumbURL = turl
                }
                if (images.length - 1 == i) {
                    console.log(i, "fdshfsfjfkbjkbggdskgds", images.length - 1);
                    resolve(images)
                }
            }

        })

    }

    cancelDelete = () => {
        this.state.images.forEach((image) => {
            image.checked = false
        })
        this.setState((state) => ({
            ...state,
            showCheckboxes: false,
            selectedImageCount: 0,
        }))
    }



    // to load tiff image 
    loadTiffImageSrc = (i) => {
        let { images, user } = this.state
        var imageURL = ""
        if (images[i].file_name) {
            let name = images[i].file_name.toLowerCase()
            if ((name.indexOf(".tif")) == -1) {
                let iurl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                imageURL = iurl
                this.setState((state) => ({
                    ...state,
                    imageURL: imageURL,
                }))
            } else {
                // for image
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                let url = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                xhr.open('GET', url);
                xhr.onload = function (e) {
                    var arraybuffer = e.target.response
                    Tiff.initialize({
                        TOTAL_MEMORY: 16777216 * 10
                    });
                    var tiff = new Tiff({
                        buffer: arraybuffer
                    })
                    var dataUrl = tiff.toDataURL();
                    let url = e.target.responseURL
                    var index = 0
                    this.state.images.map((image, j) => {
                        if (url.indexOf(encodeURIComponent(image.file_name)) != -1) {
                            index = j
                        }
                    })
                    imageURL = dataUrl   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        imageURL: imageURL,
                    }))
                }.bind(this);
                xhr.send()
            }
        }
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    zoomIn = () => {
        if (this.state.scale < 15) {
            var myImg = document.getElementById("action-image")
            var currWidth = myImg.clientWidth;
            var currHeight = myImg.clientHeight;
            myImg.style.width = (currWidth + 100) + "px";
            this.setState((state) => ({
                ...state,
                scale: this.state.scale + 1
            }))
            myImg.style.height = (currHeight + 100) + "px";
        }
    }

    zoomOut = () => {
        if (this.state.scale != 0) {
            var myImg = document.getElementById("action-image")
            var currWidth = myImg.clientWidth;
            var currHeight = myImg.clientHeight;
            this.setState((state) => ({
                ...state,
                scale: this.state.scale - 1
            }))
            myImg.style.width = (currWidth - 100) + "px";
            myImg.style.height = (currHeight - 100) + "px";
        }

    }

    getImages = () => {
        let { user, type, typeimg, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        key = key == "" ? " " : key
        // console.log("api call : ");
        API.getImageCollectionImages(this.state.collection.id, key, userperpage, firstpage, typeimg.toLowerCase()).then((data) => {
            if (data.images && data.images.length > 0) {
                if (this.state.uploadPercent >= 100 || this.state.uploadPercent == 0) {
                    const imageFileType = (data.images[0].file_name.toLowerCase()).includes("jpg") || (data.images[0].file_name.toLowerCase()).includes("jpeg") || (data.images[0].file_name.toLowerCase()).includes("png") ? AppConstants.FILETYPES.PNGJPEG : ((data.images[0].file_name.toLowerCase()).includes("tif") || (data.images[0].file_name.toLowerCase()).includes("tiff") ? AppConstants.FILETYPES.TIF : undefined)
                    this.loadThumbTiff(data.images, imageFileType).then((tiffImg) => {
                        //console.log('call back of load tiff', tiffImg);
                        this.setState((state) => ({
                            ...state,
                            loading: false,
                            showProcessPopup: this.state.keepOpenPopup ? true : false,
                            images: tiffImg,
                            sasToken: data.sasToken,
                            counts: data.totalCount,
                            showCheckboxes: false,
                            selectedImageCount: 0,
                            imageFileType,
                            // isTaskPresent: data.tasksCount > 0,
                        }), () => {
                            if (this.state.sortType === 'A-Z') {
                                this.sortByName()
                            } else if (this.state.sortType === 'Z-A') {
                                this.reverseByName()
                            } else if (this.state.sortType === 'First Modified') {
                                this.sortByDate()
                            } else if (this.state.sortType === 'Last Modified') {
                                this.reverseByDate()
                            }
                            if (this.state.mapView) {
                                this.setState((state) => ({
                                    ...state,
                                    mapView: false
                                }), () => {
                                    this.setState((state) => ({
                                        ...state,
                                        mapView: true
                                    }))
                                })
                            }
                        })
                    })

                }
            } else {
                this.setState((state) => ({
                    ...state,
                    images: [],
                    // sasToken: '',
                    counts: 0,
                    imageFileType: undefined,
                    // showCheckboxes: false,
                    selectedImageCount: 0,
                    loading: false,
                    showProcessPopup: this.state.keepOpenPopup ? true : false,
                }))
            }

        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                loading: false,
                showProcessPopup: this.state.keepOpenPopup ? true : false,
            }))
        })
    }

    onChangeSortEvent = (sortType) => {
        console.log(sortType);
        if (sortType === 'Last Modified') {
            this.reverseByDate()
        } else if (sortType === 'First Modified') {
            this.sortByDate()
        } else if (sortType === 'A-Z') {
            this.sortByName()
        } else if (sortType === 'Z-A') {
            this.reverseByName()
        }
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.images.sort((a, b) => a.file_name.localeCompare(b.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.images.sort((a, b) => b.file_name.localeCompare(a.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.images.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.images.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }


    nextImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            Idx: this.state.Idx + 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }

    prevImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            Idx: this.state.Idx - 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }
    openImage = (idx) => {
        // window.alert(idx)
        this.setState((state) => ({
            ...state,
            Idx: idx,
            imageLoad: true,
            scale: 0,

        }), () => {
            this.loadTiffImageSrc(this.state.Idx)
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px; }';
            document.getElementsByTagName('head')[0].appendChild(style);
            document.getElementById("view-image-popup-button").click()
        })

    }

    deleteImages = async () => {
        CustomPopup.alert("WAIT", "Deleting images, please wait...")
        let temp = [];
        let counter = 0;
        await this.state.images.forEach(img => {
            if (img.checked == true) {
                temp.push(img);
            }
            counter++;
            if (counter == this.state.images.length) {
                return temp
            }
        });
        let images = temp
        let collection_id = this.state.collection.id;
        API.deleteImages(collection_id, images, AppConstants.SERVICES.PICSTORK).then((data) => {
            this.setState((state) => ({
                ...state,

                processMessage: null,

                showCheckboxes: false
            }), () => {
                CustomPopup.alert("COMPLETE", "Images deleted successfully", this.getImages)
                this.getImages()
            })
        }, (e) => {
            CustomPopup.alert("ERROR", e,)
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        getFeaturesPermissions([PICSTORK_FEATURES.IMAGECOLLECTIONS])
            .then(permissions => {
                this.setState({ permissions }, () => {
                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            console.log("ID : ", id)
                            this.setState((state) => ({
                                outId: id
                            }))
                        })
                    if (this.props.location.state) {
                        let { user, type, task, project, plan, collection } = this.props.location.state;
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            task,
                            project,
                            plan,
                            collection
                        }), () => {
                            console.log("PROJECTS : ", project)
                            this.getImages();

                            API.getUserSubbedServices().then(
                                (response) => {
                                    let services = response[0];
                                    // console.log(services);
                                    if (services.length == 1 && services[0].title == 'AeroStream') {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: false
                                        }))
                                    } else {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: true
                                        }))
                                    }
                                }
                            )
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            //console.log("ROLE ", type);
                            //console.log("USER ", user)
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }), () => {
                                this.getImages()

                                //  console.log("ASKDJHASK", user);
                            })
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }
                })
            })
            .catch(e => {
                console.log(e)
                window.location.replace("/");
            })
    }
    //Get SearchKey Function Start Here-----
    search(key) {
        if (key == '') {
            this.setState((state) => ({
                ...state,
                hasMoreItems: true
            }))
        }
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getImages());
    }
    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getImages();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        console.log('next called');
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached next page")
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getImages());
        }

    }
    handleChange = (event) => {
        this.setState({ collection_name: event.target.value });
    }


    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ task_name: e.target.value });
            }
    }


    imageSelectDeleted = (key) => {
        this.state.images[key].checked = !this.state.images[key].checked
        if (this.state.images[key].checked == true) {
            let cnt = this.state.selectedImageCount + 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        } else {
            let cnt = this.state.selectedImageCount - 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        }

    }
    canImportFromDN = async (map) => {
        const selectedDataSize = Number(map[0].img_size);
        const serviceStorageObj = await getStorageUserByService(AppConstants.SERVICES.PICSTORK);
        const availableStorage = serviceStorageObj.storageLimit - serviceStorageObj.storageUsed;
        console.log("availableStorage : ", availableStorage, "\nselectedDataSize :", selectedDataSize)
        return !(availableStorage < selectedDataSize);
    }
    importSelectedOrtho = async (maps) => {
        if (await this.canImportFromDN(maps)) API.importMapsIntoCollection(maps, this.state.collection.id).then(data => {
            this.setState({ map_task_id: maps[0].task_id }, () => {
                this.getImages()
            })
        })
        else this.setState({ showStorageExtendeedPopup: true })
    }
    onSelectTypeForUpload = (selectedFileType, importFrom) => {

        this.setState({
            map_task_id: undefined,
            itemName: undefined,
            showProcessPopup: false,
            processPopupIcon: undefined,
            processMessage: undefined,
            selectedFileType: selectedFileType
        }, () => {

            if (importFrom === "GDRIVE") {

                this.setState({ startGoogleDriveSelection: true })
            }
            else {
                document.getElementById("hiddenFileInput").click()
            }

        })
    }
    getCards = () => {
        let cards = {
            upload_images: true,
            upload_tiff: true,
            import_from_dn: true,
            import_zip: true
        }
        if (this.state.imageFileType === AppConstants.FILETYPES.TIF) {
            cards.upload_images = false;
            cards.import_zip = false;
        } else
            if (this.state.imageFileType === AppConstants.FILETYPES.PNGJPEG) {
                cards.upload_tiff = false;
                cards.import_from_dn = false
                cards.import_from_googledrive = true;
                cards.import_zip = false;
            }
        return cards
    }
    render() {
        let { user, type, images } = this.state
        return (
            user.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/picstork/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"PicStork"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>

                            {/* <div className={"NonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/picstork/image_collections",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ImageSetInActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Imagesets"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div> */}
                            <div className={"selectBorder"} >
                                <li>
                                    <Link>
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={imageActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                            >
                                                Images
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://aeromegh.com/docs/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div id='tiffloaded' className="right-content" style={{}}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <Popup
                        style={{ width: "min-content" }}

                        trigger={<button id="view-image-popup-button" style={{
                            display: "none",
                        }}
                        ></button>}
                        modal
                    ><img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "25px",
                                position: "absolute",
                                top: "-12px",
                                right: "-12px",
                                cursor: "pointer"

                            }} onClick={() => {
                                var style = document.createElement('style');
                                style.type = 'text/css';
                                style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
                                document.getElementsByTagName('head')[0].appendChild(style);
                                document.getElementById("view-image-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    regions: [],
                                    scale: 0,
                                    imageLoad: false,
                                }));

                            }} />
                        <div style={{ display: 'flex' }} >
                            <div className='flex-center' style={{ alignItems: 'end', background: "#666666", width: "790px", height: '567px', borderRadius: '15px', overflow: "hidden" }}>
                                {this.state.imageLoad ?
                                    <img className='image-popup' src={Loader} style={{ position: "absolute", zIndex: 5, height: "100%", width: "8vw", marginLeft: "10%" }} /> : ''
                                }
                                <TransformWrapper>
                                    {({ zoomIn, zoomOut, centerView, resetTransform, ...rest }) => (
                                        <TransformComponent>
                                            <img id="action-image" style={{ zIndex: 0, opacity: this.state.imageLoad ? 0 : 1 }} className='image-popup' loading='lazy'
                                                src={this.state.images && this.state.images.length ? this.state.imageURL : ''}
                                                onLoad={((load) => {
                                                    console.log("image Loaded")
                                                    this.setState((state) => ({
                                                        ...state,
                                                        imageLoad: false,
                                                    }),
                                                        centerView(),
                                                        resetTransform())
                                                })} />
                                        </TransformComponent>
                                    )}
                                </TransformWrapper>
                                {this.state.images && this.state.images.length ? <div className='imageinfo'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="imagePopupTitle">{images[this.state.Idx].file_name.length > 50 ? images[this.state.Idx].file_name.substring(0, 50) : images[this.state.Idx].file_name}</div>
                                        <div className="imagePopupSubTitleTile">
                                            <div className="imagePopupSubTitle">{images[this.state.Idx].resolution} MP</div>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="imagePopupSubTitle">
                                            {images[this.state.Idx].latitude == null || images[this.state.Idx].latitude == "" ? "-" : `${images[this.state.Idx].latitude.length > 11 ? `${images[this.state.Idx].latitude.substring(0, 11)}` : `${images[this.state.Idx].latitude}`}`}, {images[this.state.Idx].longitude == null || images[this.state.Idx].longitude == "" ? "-" : `${images[this.state.Idx].longitude.length > 11 ? `${images[this.state.Idx].longitude.substring(0, 11)}` : `${images[this.state.Idx].longitude}`}`}
                                        </div>
                                        <div className="imagePopupSubTitleTile">
                                            <div className="imagePopupSubTitle">{images[this.state.Idx].file_size} MB</div>
                                        </div>
                                    </div>
                                </div> : ''}

                                {this.state.images && this.state.images.length > 1 ? < div className='nextprevfunction'>
                                    {
                                        this.state.Idx == 0 ?
                                            <div className='prevDiv'>
                                                <img className='prev' src={circleLeft} style={{ opacity: '0.5' }} />
                                            </div> :
                                            <div className='prevDiv' onClick={this.prevImg}>
                                                <img className='prev' src={circleLeft} style={{ ursor: 'pointer' }} />
                                            </div>

                                    }
                                    {
                                        this.state.Idx == this.state.images.length - 1 ?
                                            <div className='nextDiv'> <img className='next' src={circleRight} style={{ opacity: '0.5' }}
                                            /> </div> :
                                            <div className='nextDiv' onClick={this.nextImg} >
                                                <img className='next' src={circleRight} style={{ cursor: 'pointer' }} />
                                            </div>
                                    }

                                </div> : ''
                                }
                            </div>
                        </div>
                    </Popup>

                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.state.showCheckboxes ? this.deleteImages() : this.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)}
                    />
                    {/* --------------------------------------------------------------- */}


                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",

                                    pathname: "/picstork/dashboard",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: this.props.location.state.project.project_name,

                                    pathname: "/picstork/project",
                                    state: {
                                        type,
                                        user,
                                        project: this.props.location.state.project,
                                    }
                                },
                                {
                                    displayName: this.state.collection.collection_name
                                }
                            ]} />
                            <ActiveRole />
                        </div>
                    </div>

                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <>
                            <div className="main-content" style={{ background: 'none' }} onClick={
                                () => {
                                    this.setState((state) => ({
                                        ...state, sidebarMode: 'shrunk'
                                    }))
                                }
                            }>
                                {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                                <div id='tiffloaded' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                                    <div className="services-content-title">Images
                                        <span className='data-count'>({this.state.counts})</span>
                                    </div>
                                    <div className="add-user-form-text-wrapper" style={{ display: 'flex', alignItems: 'center', height: '35px' }}
                                    >
                                        <div style={{ marginRight: '20px' }}>
                                            <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                            <input type="text" value={this.state.key} className="add-user-form-text2" placeholder="Search images" style={{ height: '28px', width: "230px", paddingLeft: '28px', border: '1px solid rgba(102, 102, 102, 0.3)' }}
                                                onChange={(event) => this.search(event.target.value)} />
                                        </div>

                                        <div className="imageViewButton" style={{ display: 'flex', height: '24px', marginRight: '20px' }}  >
                                            <div className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "55px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf', height: '24px' }}
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        listview: true,
                                                        gridView: false,
                                                        mapView: false,

                                                    }))
                                                }}>
                                                <div> <img src={this.state.listview ? listViewActiveIcon : listViewIcon} style={{ height: '16px', width: '16px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                            </div>
                                            <div className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "55px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf', height: '24px' }}
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        listview: false,
                                                        gridView: true,
                                                        mapView: false,

                                                    }))
                                                }}
                                            >
                                                <div><img src={this.state.gridView ? gridViewActiveIcon : gridViewIcon} style={{ height: '16px', width: '16px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                            </div>

                                            <div className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "55px", borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf', height: '24px' }}
                                                onClick={() => {
                                                    this.cancelDelete()
                                                    this.setState((state) => ({
                                                        ...state,
                                                        listview: false,
                                                        gridView: false,
                                                        mapView: true,
                                                    }))
                                                }}>
                                                <div><img src={this.state.mapView ? mapViewActive : mapViewIcon} style={{ height: '18px', width: '18px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                            </div>
                                        </div>


                                        {this.state.showCheckboxes ?
                                            <>
                                                <div className="addcustomer-button-empty-dashboard" style={{ fontSize: '10px', marginRight: '25px', padding: '6px 20px', cursor: 'pointer' }}
                                                    onClick={() => { this.cancelDelete() }}
                                                >Cancel</div>
                                                {this.state.selectedImageCount > 0 ?
                                                    < img src={trashIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', marginRight: '20px', marginTop: "5px" }}
                                                        onClick={() => {
                                                            CustomPopup.alert('WARNING', "Are you sure, you want to delete these images ?", this.deleteImages)
                                                        }}
                                                    /> : ''}
                                            </> :
                                            <>
                                                {!this.state.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS].EDIT ? <></>
                                                    :
                                                    <>
                                                        <div style={{ display: "none" }}>
                                                            {<MultiFileUploader
                                                                serviceName={AppConstants.SERVICES.PICSTORK}
                                                                acceptFileType={this.state.imageFileType || this.state.selectedFileType}
                                                                // acceptSingleFileOnly={this.state.selectedFileType === AppConstants.FILETYPES.TIF}
                                                                collection={this.state.collection}
                                                                totalImageCount={this.state.counts}
                                                                oldFiles={this.state.images}
                                                                user={this.state.user.userid}
                                                                openImportPopup={({ showProcessPopup, processPopupIcon, processMessage, keepOpenPopup }) => {
                                                                    this.setState({
                                                                        showProcessPopup,
                                                                        processPopupIcon,
                                                                        processMessage,
                                                                        keepOpenPopup
                                                                    })
                                                                }}
                                                                startGoogleDriveSelection={this.state.startGoogleDriveSelection}
                                                                clearGoogleDriveStates={() => {
                                                                    this.setState({ startGoogleDriveSelection: false })
                                                                }}
                                                                callback={(n, files) => { this.setState((prevState) => ({ ...prevState, fileCount: n, images: files })) }}
                                                                onImageUpload={(uploadPercent) => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        uploadPercent: uploadPercent
                                                                    }), () => this.getImages())
                                                                }}
                                                            />}
                                                        </div>
                                                        <ImagesTypeSelection
                                                            AnimatedModalStyle={{ width: "max-content" }}
                                                            uploadedImageCount={0}
                                                            collectionId={this.props.collectionId || this.state.collection.id}
                                                            permissions={this.state.permissions}
                                                            disableOrthomosaic={false}
                                                            disableApplicationType={false}
                                                            uploadOk={() => this.getImages()}
                                                            onSelectTypeForUpload={
                                                                (fileType, importFrom) => {
                                                                    if (fileType === AppConstants.FILETYPES.TIF) {
                                                                        this.setState({
                                                                            itemName: "WARNING",
                                                                            showProcessPopup: true,
                                                                            processPopupIcon: "WARNING",
                                                                            processMessage: "Make sure you are uploading Cloud Optimized GeoTIFF (COG). Do you want to continue ?",
                                                                        })
                                                                    } else {
                                                                        this.onSelectTypeForUpload(fileType, importFrom)
                                                                    }
                                                                }}
                                                            importSelectedOrtho={this.importSelectedOrtho}
                                                            isDisabled={!(this.state.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS]?.EDIT)}
                                                            user={this.state.user}
                                                            normalImages={this.state.imageFileType === AppConstants.FILETYPES.PNGJPEG}
                                                            droneNakshaCards={this.state.images.length != 0}
                                                            cards={this.getCards()}
                                                        />
                                                    </>
                                                }</>
                                        }
                                        {!this.state.mapView ? <Dropdown style={{}}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" title=""
                                                className={this.state.images == 0 ? "custom-dropdown-settings1nodata" : "custom-dropdown-settings1"}
                                            >
                                                <Dropdown.Item
                                                    style={{ padding: '0px !important' }}
                                                    className={this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].DELETE && images.length != 0 ? "dropdown-set-padding" : "option-disabled"}
                                                ><div style={{ fontSize: "12px", color: '#3c3c3c' }}
                                                    onClick={
                                                        () => {
                                                            if (this.state.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].DELETE && images.length != 0) {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    showCheckboxes: true
                                                                }))
                                                            }
                                                        }
                                                    }>Delete Images</div>
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown> : <></>}

                                    </div>

                                </div>
                                {this.state.mapView ?
                                    <div>
                                        <ViewImagesOnMap
                                            service={AppConstants.SERVICES.PICSTORK}
                                            height={'84vh'}
                                            width={'100%'}
                                            inspect={this.state.images}
                                            collection_id={this.state.collection.id}
                                            project_id={this.state.project.id}
                                            getImages={() => this.getImages()}
                                            onClickOnPoint={idx => this.openImage(idx)}
                                            disableExport={true}
                                        />
                                    </div>
                                    : <>{this.state.listview && images.length > 0 ?
                                        <div style={{ height: '84vh', overflow: 'auto' }}>
                                            <ViewImageInList user={this.state.user} showCheckboxes={this.state.showCheckboxes} collection_id={this.state.collection.id} inspect={this.state.images} onClickOnCheckBox={key => this.imageSelectDeleted(key)} sortType={this.state.sortType}
                                                onClickOnPoint={idx => this.openImage(idx)}
                                                onClickSort={sortType => this.onChangeSortEvent(sortType)} />
                                        </div>
                                        : <>   {images.length ?
                                            <div style={{ height: '84vh', overflow: 'auto' }} >
                                                <div className="aerocapture-cards-tray-images1" >
                                                    {
                                                        images.map((image, key) => {
                                                            // let name = image.file_name.toLowerCase()
                                                            return (
                                                                <div key={key}>
                                                                    <div key={key}>
                                                                        {< div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', width: '185px' }}>
                                                                            <div className="card-header customer-cards-dashboard-logo project-card-header"
                                                                                style={{}}

                                                                            >
                                                                                {/* {name.indexOf(".tif") == -1 ? */}
                                                                                <img key={key} className="image-cards-dashboard" id={image.file_name}
                                                                                    src={image.thumbURL}
                                                                                    loading='lazy'
                                                                                    onClick={
                                                                                        () => {
                                                                                            if (this.state.showCheckboxes) {
                                                                                                this.imageSelectDeleted(key)
                                                                                            } else {
                                                                                                this.openImage(key)
                                                                                            }
                                                                                        }
                                                                                    } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />
                                                                                {/* :
                                                                                    <img className="image-cards-dashboard" id={image.file_name}
                                                                                        src={image.thumbURL}
                                                                                        loading='eager'
                                                                                        onClick={
                                                                                            () => {
                                                                                                if (this.state.showCheckboxes) {
                                                                                                    this.imageSelectDeleted(key)
                                                                                                } else {
                                                                                                    this.openImage(key)
                                                                                                }
                                                                                            }
                                                                                        } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                        onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />
                                                                                } */}
                                                                                {this.state.showCheckboxes ? <input type='checkbox' id={image.file_name} checked={image.checked} style={{ height: '15px', width: '15px', marginLeft: 'auto', marginRight: "-155px", marginTop: '-40px', position: 'absolute' }}
                                                                                    onClick={() => { this.imageSelectDeleted(key) }}
                                                                                /> : <> </>
                                                                                }
                                                                            </div>
                                                                            <div style={{ paddingTop: "3px !important" }}
                                                                                className="card-body change-color customer-cards-dashboard-text project-card-body2"
                                                                            >
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <span style={{ fontSize: '11px' }}>{
                                                                                        image.file_name.length > 20 ?
                                                                                            `${image.file_name.substring(0, 20)}...` :
                                                                                            `${image.file_name}`
                                                                                    }</span>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <div style={{ fontSize: '11px', height: '12px' }}>
                                                                                            {image.latitude == "" || image.longitude == "" || image.latitude == null || image.longitude == null || image.latitude == '0' || image.longitude == '0' ? <></> : <img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} />}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.file_size} MB</div>
                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.resolution} MP</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                                <img style={{ height: '150px', width: '150px' }} src={Noimageset} />
                                                <p style={{ marginTop: '10px' }}>No images available</p>
                                            </div>
                                        }
                                            {/* This is footer  */}
                                            {/* {this.state.showCheckboxes ? '' :
                                                <footer className="footer" style={{ visibility: (this.state.counts > userperpage ? "visible" : "hidden"), position: 'fixed', width: '100%', bottom: '3%' }} >
                                                    <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                                        <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                                            Page : {currentpage}
                                                        </div>


                                                    </div>
                                                    <div style={{ float: "right", marginTop: "0.5rem", display: "flex", marginRight: '13.5rem' }}>
                                                        {this.state.currentpage == 1 ?
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                                                <div className="add-user-form-submit-button" >
                                                                    First
                                                                </div>
                                                            </button> :
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                                                <div className="add-user-form-submit-button" >
                                                                    First
                                                                </div>
                                                            </button>}
                                                        {this.state.currentpage == 1 ?
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                                <div className="addcustomer-button-empty-dashboard" >
                                                                    Prev
                                                                </div>
                                                            </button> :

                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                                                <div className="addcustomer-button-empty-dashboard" >
                                                                    Prev
                                                                </div>
                                                            </button>}
                                                        <form>
                                                            <div style={{ display: "flex" }}>
                                                                <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                                    Go to page :
                                                                </div>

                                                                <div className="add-user-form-text-wrapper">
                                                                    <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }} onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                                                <div className="addcustomer-button-empty-dashboard" >
                                                                    Next
                                                                </div>
                                                            </button> :
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                                                <div className="addcustomer-button-empty-dashboard" >
                                                                    Next
                                                                </div>
                                                            </button>
                                                        }
                                                        {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                                <div className="add-user-form-submit-button" >
                                                                    Last
                                                                </div>
                                                            </button> :
                                                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                                                <div className="add-user-form-submit-button" >
                                                                    Last
                                                                </div>
                                                            </button>}
                                                    </div>
                                                </footer>} */}
                                        </>}</>}
                            </div>
                        </>
                    }
                </div>



            </div > : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(AeroCaptureImageCollectionImages);

